<ng-container *appTemplateContent="'title'">Aggregate Leave ({{dataSource.filteredData.length}})</ng-container>

<!--toolbar-->
<ng-container *appToolbarContent>
  <button mat-icon-button>
    <mat-icon>search</mat-icon>
  </button>
  <mat-form-field floatLabel="never">
    <input autofocus matInput [(ngModel)]="urlBinding.values.search" placeholder="Search">
  </mat-form-field>
  <mat-form-field style="padding-left: 8px; width: 100px">
    <mat-select [(ngModel)]="urlBinding.values.year"
                placeholder="School Year">
      <mat-option *ngFor="let year of years" [value]="year.value">{{year.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-icon-button (click)="filterDialog.show()" class="print-hide" matTooltip="Filters">
    <mat-icon>filter_list</mat-icon>
  </button>
  <a mat-button [routerLink]="['/leave-request', 'list', isSupervisor ? 'supervisor':'all']">
    <mat-icon mat-list-icon>supervisor_account</mat-icon>
    Show Leave Requests
  </a>
  <app-export-button [values]="dataSource" [columns]="exportColumns" fileName="leaveSummaryData"></app-export-button>
</ng-container>

<!--filter dialog-->
<ng-template #filterDialog="dialog" appDialog="Filter">
  <div class="two-colum-grid">
    <mat-checkbox [(ngModel)]="urlBinding.values.showThai">
      Show Thai Staff
    </mat-checkbox>
    <mat-checkbox [(ngModel)]="urlBinding.values.showNonThai">
      Show Non Thai Staff
    </mat-checkbox>
    <mat-form-field>
      <mat-select placeholder="Filter Department" [(ngModel)]="urlBinding.values.group" multiple>
        <mat-option *ngFor="let groupName of allOrgGroups" [value]="groupName">{{groupName}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="Filter Sending Org" [(ngModel)]="urlBinding.values.sendingOrg" multiple>
        <mat-option *ngFor="let orgName of allMissionOrgs" [value]="orgName">{{orgName}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-template>

<!--table-->
<mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="staff">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Staff</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.person.preferredName}} {{row.person.lastName}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="person.staff.orgGroupName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Department / Division</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.person.staff.orgGroupName}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="person.staff.missionOrgName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Sending Org</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.person.staff.missionOrgName}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="vacation.used">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Vacation</mat-header-cell>
    <mat-cell *matCellDef="let row"
              [class.warn]="row.vacation?.used > row.vacation?.totalAllowed">
      {{row.vacation?.used || 0}}/{{row.vacation?.totalAllowed || 0}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="sick.used">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Sick</mat-header-cell>
    <mat-cell *matCellDef="let row"
              [class.warn]="row.sick?.used > row.sick?.totalAllowed">
      {{row.sick?.used}}/{{row.sick?.totalAllowed}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="personal.used">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Personal</mat-header-cell>
    <mat-cell *matCellDef="let row"
              [class.warn]="row.personal?.used > row.personal?.totalAllowed">
      {{row.personal?.used}}/{{row.personal?.totalAllowed}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="parental.used">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Maternity/Paternity</mat-header-cell>
    <mat-cell *matCellDef="let row"
              [class.warn]="row.parental?.used > row.parental?.totalAllowed">
      {{row.parental?.used}}/{{row.parental?.totalAllowed}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="emergency.used">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Emergency</mat-header-cell>
    <mat-cell *matCellDef="let row"
              [class.warn]="row.emergency?.used > row.emergency?.totalAllowed">
      {{row.emergency?.used || 0}}/{{row.emergency?.totalAllowed || 0}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="schoolRelated.used">
    <mat-header-cell *matHeaderCellDef mat-sort-header>School Related</mat-header-cell>
    <mat-cell *matCellDef="let row"
              [class.warn]="row.schoolRelated?.used > row.schoolRelated?.totalAllowed">
      {{row.schoolRelated?.used || 0}}/{{row.schoolRelated?.totalAllowed || 0}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="missionRelated.used">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Mission Related</mat-header-cell>
    <mat-cell *matCellDef="let row"
              [class.warn]="row.missionRelated?.used > row.missionRelated?.totalAllowed">
      {{row.missionRelated?.used || 0}}/{{row.missionRelated?.totalAllowed || 0}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="other.used">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Other</mat-header-cell>
    <mat-cell *matCellDef="let row"
              [class.warn]="row.other?.used > row.other?.totalAllowed">
      {{row.other?.used || 0}}/{{row.other?.totalAllowed || 0}}
    </mat-cell>
  </ng-container>

  <!-- Header and Row Declarations -->
  <mat-header-row
    *matHeaderRowDef="columns"></mat-header-row>
  <mat-row class="hover-overlay" [routerLink]="'/leave-request/list/' + row.person.id"
           *matRowDef="let row; columns: columns">
  </mat-row>
</mat-table>

<a [routerLink]="['/leave-request','edit','new']"
   [queryParams]="{noNotification: true}"
   class="add-button"
   mat-fab matTooltip="Add new Leave Request"
   matTooltipPosition="left">
  <mat-icon>add</mat-icon>
</a>
