<ng-container *appTemplateContent="'title'">Edit Required Training</ng-container>
<div class="main-card">
  <mat-card>
    <form (submit)="save()" class="two-colum-grid" #form="ngForm" id="form">
      <mat-form-field>
        <input matInput required name="name" [(ngModel)]="training.name" placeholder="Name">
      </mat-form-field>
      <mat-form-field>
        <mat-select [(ngModel)]="training.ownerId"
                    name="owner"
                    placeholder="Owner">
          <mat-option [value]="null">None</mat-option>
          <mat-option *ngFor="let eachPerson of people" [value]="eachPerson.id">{{eachPerson.preferredName}}
            {{eachPerson.lastName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="span-2-columns">
        <input matInput name="provider" [(ngModel)]="training.provider" placeholder="Provider">
      </mat-form-field>

      <mat-form-field>
        <mat-select name="scope" required [(ngModel)]="for" placeholder="For">
          <mat-option value="AllStaff">All Staff</mat-option>
          <mat-option *ngFor="let group of groups" [value]="group.id">{{group.groupName}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-select [(ngModel)]="training.jobScope" name="type" [multiple]="true"
                    placeholder="Job Type Scope">
          <mat-option *ngFor="let type of jobTypes" [value]="type">{{type | titleCase}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-slide-toggle name="trainingOnce"
                        [ngModel]="training.renewMonthsCount == -1"
                        (ngModelChange)="training.renewMonthsCount = $event ? -1 : 12">
        Training Only required once
      </mat-slide-toggle>
      <mat-form-field *ngIf="training.renewMonthsCount != -1">
        <input matInput required
               type="number"
               [min]="1"
               name="renewMonths"
               [(ngModel)]="training.renewMonthsCount"
               placeholder="Months Between Training">
      </mat-form-field>
      <span *ngIf="training.renewMonthsCount == -1"></span>
      <mat-form-field>
        <mat-select name="firstYear" required [(ngModel)]="training.firstYear" placeholder="First year of training">
          <mat-option *ngFor="let year of years" [value]="year.value">{{year.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select name="lastYear" [(ngModel)]="training.lastYear" placeholder="Last year of training">
          <mat-option>Unknown</mat-option>
          <mat-option *ngFor="let year of years" [value]="year.value">{{year.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <mat-card-actions>
      <button mat-button form="form" type="submit" [disabled]="form.form.invalid">Save</button>
      <button mat-button *ngIf="training.id" (click)="delete()" color="warn">Delete</button>
    </mat-card-actions>
  </mat-card>
</div>
