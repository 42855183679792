import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../services/auth/login.service';
import { AuthenticateService } from '../services/auth/authenticate.service';
export class LoginComponent {
    constructor(authenticateService, loginService, router, route, snackBar, document) {
        this.authenticateService = authenticateService;
        this.loginService = loginService;
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.document = document;
        this.passwordReset = false;
    }
    get redirectUrl() {
        return this.route.snapshot.queryParams['redirect'] || this.loginService.redirectTo;
    }
    ngOnInit() {
    }
    login() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.errorMessage = '';
            let user;
            try {
                user = yield this.authenticateService.login(this.username, this.password, this.passwordReset ? this.newPassword : null);
            }
            catch (errorResponse) {
                this.errorMessage = errorResponse.error.message;
                return;
            }
            if (user.resetPassword) {
                this.passwordReset = true;
                this.snackBar.open('Password reset required', 'Dissmiss', { duration: 2000 });
                return;
            }
            else if (this.route.snapshot.queryParamMap.has('redirect')) {
                this.document.location.href = this.route.snapshot.queryParamMap.get('redirect');
            }
            else {
                let success = yield this.router.navigate([this.loginService.redirectTo]);
                if (!success)
                    this.router.navigate(['home']);
            }
        });
    }
}
