<ng-container *appTemplateContent="'title'">Organization</ng-container>
<ng-container *appToolbarContent>
  <mat-slide-toggle [(ngModel)]="urlBinding.values.allJobs" style="padding-right: 16px;">
    Show Inactive Jobs
  </mat-slide-toggle>
  <mat-slide-toggle [(ngModel)]="urlBinding.values.allRoles">
    Show Role History
  </mat-slide-toggle>

  <mat-form-field>
    <mat-select multiple [(ngModel)]="urlBinding.values.show" placeholder="Show">
      <mat-option value="staff">Staff</mat-option>
      <mat-option value="aids">School Aids</mat-option>
    </mat-select>
  </mat-form-field>

  <a mat-icon-button class="print-hide" matTooltip="Org List" matTooltipPosition="before"
     routerLink="/groups/list">
    <mat-icon>list_alt</mat-icon>
  </a>
</ng-container>
<div class="main-card">
  <mat-card>
    <mat-tree [dataSource]="nodes" [treeControl]="treeControl" [trackBy]="trackNodesBy">
      <ng-container *matTreeNodeDef="let node; when: isOrgNode">
        <mat-nested-tree-node role="group">
          <div class="mat-tree-node" matTreeNodePadding>
            <button mat-icon-button
                    (click)="nodeToggled(node)" matTreeNodeToggle
                    [style.visibility]="node.hasChildren ? 'visible' : 'hidden'"
                    [attr.aria-label]="'toggle ' + node.value.groupName">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <a mat-button [routerLink]="['/groups', 'edit', node.id]">
              <mat-icon>location_city</mat-icon>
              {{node.value.groupName}}
            </a>
            <span class="spacer"></span>
            <mat-chip-list>
              <a mat-chip
                 *ngIf="node.value.supervisorPerson as person"
                 [routerLink]="['/people', 'edit', person.id]"
                 matTooltip="This supervisor is over more then 1 Department/Division, please correct this"
                 [matTooltipDisabled]="orgTree.supervisorCountById[node.value.supervisor] <= 1"
                 [selected]="orgTree.supervisorCountById[node.value.supervisor] > 1"
                 color="warn">
                <!--                color only shows up if it's selected-->
                Supervisor {{person.preferredName}} {{person.lastName}}
                <mat-icon matChipRemove>person</mat-icon>
              </a>
              <mat-chip *ngIf="urlBinding.values.show.includes('staff')"
                        [selected]="true" color="accent">
                Staff: {{node.activeStaff}}
              </mat-chip>
              <mat-chip *ngIf="urlBinding.values.show.includes('aids')"
                        [selected]="true" color="accent">
                Aids: {{node.activeAids}}
              </mat-chip>
              <mat-chip [selected]="true" color="primary">Open Jobs: {{node.openJobs}}</mat-chip>
            </mat-chip-list>
            <button mat-icon-button [matMenuTriggerFor]="groupMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #groupMenu>
              <a [routerLink]="['/groups', 'edit', node.id]" mat-menu-item>
                <mat-icon>edit</mat-icon>
                Edit {{node.value.groupName}}
              </a>
              <a *ngIf="node.value.supervisorPerson as person"
                 [routerLink]="['/person', 'edit', node.value.supervisor]"
                 mat-menu-item>
                <mat-icon>person</mat-icon>
                Edit {{person.preferredName}} {{person.lastName}}
              </a>
              <a routerLink="/job/list" [queryParams]="{group: node.allDecendantsGroups}" mat-menu-item>
                <mat-icon>work</mat-icon>
                View Jobs
              </a>
              <a routerLink="/job/report/roles" [queryParams]="{group: node.allDecendantsGroups}" mat-menu-item>
                <mat-icon>work</mat-icon>
                View Roles
              </a>
              <a routerLink="/people/staff/report" [queryParams]="{group: node.allDecendantsGroups}" mat-menu-item>
                <mat-icon>business</mat-icon>
                View Staff
              </a>
              <a *ngIf="node.value.supervisor"
                 [routerLink]="['/leave-request', 'list', 'supervisor', node.value.supervisor]"
                 mat-menu-item>
                <mat-icon>transfer_within_a_station</mat-icon>
                View Leave
              </a>
              <a *ngIf="node.value.supervisor"
                 [routerLink]="['/calendar', 'supervisor', node.value.supervisor]"
                 mat-menu-item>
                <mat-icon>date_range</mat-icon>
                View Calendar
              </a>
            </mat-menu>
          </div>
          <mat-divider *ngIf="!(node|isLastVisible:treeControl.isExpanded(node):nodes)"
                       [inset]="true"
                       [style.margin-left]="dividerMargin(node)"
                       [style.width]="'calc(100% - ' + dividerMargin(node) + ')'"></mat-divider>
          <ng-container *ngIf="treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ng-container>
        </mat-nested-tree-node>
      </ng-container>

      <ng-container *matTreeNodeDef="let node; when: isJobNode">
        <mat-nested-tree-node role="group">
          <div class="mat-tree-node" matTreeNodePadding>
            <button mat-icon-button matTreeNodeToggle
                    (click)="nodeToggled(node)"
                    [style.visibility]="node.hasChildren ? 'visible' : 'hidden'"
                    [attr.aria-label]="'toggle ' + node.value.title">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <a mat-button [routerLink]="['/job', 'edit', node.id]">
              <mat-icon>work</mat-icon>
              {{node.value.title}}
            </a>
            <span class="spacer"></span>
            <mat-chip-list>
              <mat-chip *ngIf="urlBinding.values.show.includes('staff')"
                        [selected]="true" color="accent">
                Staff: {{node.activeStaff}}
              </mat-chip>
              <mat-chip *ngIf="urlBinding.values.show.includes('aids')"
                        [selected]="true" color="accent">
                Aids: {{node.activeAids}}
              </mat-chip>
              <mat-chip [selected]="true" color="primary">Open Jobs: {{node.openJobs}}</mat-chip>
            </mat-chip-list>
            <button mat-icon-button [matMenuTriggerFor]="jobMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #jobMenu>
              <a [routerLink]="['/job', 'edit', node.id]" mat-menu-item>
                <mat-icon>edit</mat-icon>
                Edit Job
              </a>
            </mat-menu>
          </div>
          <mat-divider *ngIf="!(node|isLastVisible:treeControl.isExpanded(node):nodes)"
                       [inset]="true"
                       [style.margin-left]="dividerMargin(node)"
                       [style.width]="'calc(100% - ' + dividerMargin(node) + ')'"></mat-divider>
          <ng-container *ngIf="treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ng-container>
        </mat-nested-tree-node>
      </ng-container>

      <ng-container *matTreeNodeDef="let node; when: isRoleNode">
        <mat-tree-node matTreeNodeToggle matTreeNodePadding>
          <button mat-icon-button disabled></button>
          <a mat-button [routerLink]="['/people', 'edit', node.id]">
            <mat-icon>person</mat-icon>
            {{node.value.preferredName}} {{node.value.lastName}}
          </a>
          <span class="spacer"></span>
          <mat-chip-list>
            <mat-chip [selected]="true" color="accent">
              {{node.value.startDate | date}}
              <mat-icon style="font-size: 18px; height: 18px; width: 18px;">arrow_right_alt</mat-icon>
              {{node.value.active ? 'now' : (node.value.endDate | date)}}
            </mat-chip>
          </mat-chip-list>
          <button mat-icon-button [matMenuTriggerFor]="roleMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #roleMenu>
            <a [routerLink]="['/people', 'edit', node.id]" mat-menu-item>
              <mat-icon>edit</mat-icon>
              Edit Person
            </a>
          </mat-menu>
        </mat-tree-node>
        <mat-divider *ngIf="!(node|isLastVisible:treeControl.isExpanded(node):nodes)"
                     [inset]="true"
                     [style.margin-left]="dividerMargin(node)"
                     [style.width]="'calc(100% - ' + dividerMargin(node) + ')'"></mat-divider>
      </ng-container>
    </mat-tree>
  </mat-card>
</div>
