<ng-container *appTemplateContent="'title'">
  <ng-container *ngIf="isNew; else elseBlock">Request Leave</ng-container>
  <ng-template #elseBlock>Edit Leave Request</ng-template>
</ng-container>
<ng-container *appToolbarContent>
  <mat-slide-toggle *ngIf="isHr"
                    [(ngModel)]="sendNotification"
                    (ngModelChange)="warnNoLeaveNotification($event)">
    Send Approval Email
  </mat-slide-toggle>
</ng-container>
<div class="main-card">
  <mat-card>
    <form #leaveForm="ngForm" (submit)="submit()" id="form" class="two-colum-grid">
      <mat-form-field>
        <mat-select name="person"
                    [(ngModel)]="leaveRequest.personId"
                    (ngModelChange)="personSelectedChanged($event)"
                    [disabled]="isReadonly"
                    required
                    placeholder="Person Leaving">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let eachPerson of people" [value]="eachPerson.person.id">
            {{eachPerson.person.preferredName}} {{eachPerson.person.lastName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select name="type" required [disabled]="isReadonly" placeholder="Type" [(ngModel)]="leaveRequest.type">
          <ng-container *ngFor="let type of typesOfLeave">
            <mat-option [value]="type" *ngIf="showLeaveType(type)">{{type | titleCase}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <input name="startDate" required matInput
               [(ngModel)]="leaveRequest.startDate"
               (ngModelChange)="updateDaysUsed()"
               [disabled]="(leaveRequest.overrideDays && !isHr) || isReadonly"
               [matDatepicker]="startDatePicker"
               placeholder="Start Date">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <input name="endDate" required matInput
               [(ngModel)]="leaveRequest.endDate"
               (ngModelChange)="updateDaysUsed()"
               [min]="leaveRequest.startDate"
               [disabled]="(leaveRequest.overrideDays && !isHr) || isReadonly"
               [matDatepicker]="endDatePicker"
               placeholder="End Date">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
      <div class="span-2-columns">
        <mat-icon *ngIf="intersectingHolidays" [matTooltip]="'Holiday(s) included ' + intersectingHolidays">info
        </mat-icon>
        <mat-form-field [style.width]="'90px'">
          <input matInput
                 name="days"
                 type="number"
                 [readonly]="!leaveRequest.overrideDays || !isHr"
                 [(ngModel)]="leaveRequest.days"
                 min="1"
                 matTooltip="Only HR can Modify Leave calculations"
                 [matTooltipDisabled]="isHr || !leaveRequest.overrideDays"
                 placeholder="Days used">
        </mat-form-field>
        <mat-slide-toggle name="overrideDays"
                          [disabled]="!isHr || leaveRequest.days == 0.5"
                          [(ngModel)]="leaveRequest.overrideDays"
                          (ngModelChange)="overrideDaysChanged($event)"
                          matTooltip="Only HR can Override leave calculations"
                          [matTooltipDisabled]="isHr">Override Leave Calculation
        </mat-slide-toggle>
        <mat-slide-toggle name="halfDay"
                          [disabled]="(leaveRequest.days !== 0.5 && leaveRequest.days !== 1) || isReadonly"
                          [ngModel]="leaveRequest.days == 0.5"
                          (ngModelChange)="leaveRequest.days = ($event ? 0.5 : 1); leaveRequest.overrideDays = false"
                          matTooltip="Start & End date must be the same for half days"
                          [matTooltipDisabled]="leaveRequest.days == 0.5 || leaveRequest.days == 1">Is Half Day
        </mat-slide-toggle>

      </div>
      <mat-form-field class="span-2-columns">
          <textarea matInput [(ngModel)]="leaveRequest.reason"
                    [readonly]="isReadonly"
                    name="reason"
                    placeholder="Reason"></textarea>
      </mat-form-field>

      <mat-form-field>
        <mat-select name="approved"
                    [disabled]="!isHr"
                    [(ngModel)]="leaveRequest.approved"
                    (ngModelChange)="approvedChanged($event)"
                    placeholder="Approval Status">
          <mat-option [value]="null">Unknown</mat-option>
          <mat-option [value]="true">Approved</mat-option>
          <mat-option [value]="false">Unapproved</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="isHr">
        <mat-select name="approver"
                    [(ngModel)]="leaveRequest.approvedById"
                    placeholder="Approved By">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let eachPerson of people" [value]="eachPerson.person.id">
            {{eachPerson.person.preferredName}} {{eachPerson.person.lastName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input name="createdDate" matInput
               [disabled]="isReadonly"
               [(ngModel)]="leaveRequest.createdDate"
               [matDatepicker]="createdDatePicker"
               placeholder="Created Date">
        <mat-datepicker-toggle matSuffix [for]="createdDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #createdDatePicker></mat-datepicker>
      </mat-form-field>

      <div class="span-2-columns" *ngIf="selectedPerson">
        <div class="mat-caption">Current Leave (used/total)</div>
        <mat-chip-list>
          <ng-container *ngFor="let leave of selectedPerson?.leaveUsages">
            <mat-chip *ngIf="showLeaveUsage(leave)"
                      color="accent" selected="true">
              {{leave.leaveType | titleCase}} {{leave.used}}/{{leave.totalAllowed}}
            </mat-chip>
          </ng-container>
        </mat-chip-list>
      </div>
    </form>

    <mat-card-actions>
      <div class="tooltip"
           matTooltip="Only HR Can update approved leave requests"
           [matTooltipDisabled]="!isReadonly">
        <button type="submit"
                form="form"
                *ngIf="!isReadonly"
                [disabled]="!leaveForm.form.valid || isReadonly"
                mat-button>
          {{sendNotification ? 'Send Request' : (isNew ? 'Create Leave' : 'Save')}}
        </button>
      </div>
      <div
        class="tooltip"
        matTooltip="Only HR Can update approved leave requests"
        [matTooltipDisabled]="!isReadonly">
        <button type="button"
                *ngIf="sendNotification"
                [disabled]="isReadonly"
                (click)="submit(true)"
                mat-button>Save Draft
        </button>
      </div>
      <div
        class="tooltip"
        matTooltip="Only HR Can delete approved leave requests"
        [matTooltipDisabled]="!isReadonly">
        <button mat-button
                *ngIf="!isNew"
                [disabled]="isReadonly"
                color="warn"
                (click)="deleteRequest()">Delete Request
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</div>
