import { BaseEntity } from '../classes/base-entity';
export class MissionOrg extends BaseEntity {
}
export class MissionOrgWithNames extends MissionOrg {
}
export class MissionOrgWithYearSummaries extends MissionOrg {
    constructor() {
        super(...arguments);
        this.yearSummaries = [];
    }
}
export var MissionOrgStatus;
(function (MissionOrgStatus) {
    MissionOrgStatus["Associate"] = "Associate";
    MissionOrgStatus["OwnerAssociate"] = "OwnerAssociate";
    MissionOrgStatus["FoundingAssociate"] = "FoundingAssociate";
    MissionOrgStatus["Founder"] = "Founder";
})(MissionOrgStatus || (MissionOrgStatus = {}));
