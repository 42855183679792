<ng-container *appTemplateContent="'title'">Holiday {{holiday.name}}</ng-container>
<form class="main-card" #form="ngForm" (submit)="save()">
  <mat-card>
    <mat-card-content class="two-colum-grid">
      <mat-form-field>
        <input matInput name="name" [(ngModel)]="holiday.name" required placeholder="Name">
      </mat-form-field>
      <div></div>
      <mat-form-field>
        <input name="startDate" required matInput
               [(ngModel)]="holiday.start"
               [max]="holiday.end"
               [matDatepicker]="startDatePicker"
               placeholder="Start Date">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>

        <input name="endDate" required matInput
               [(ngModel)]="holiday.end"
               [min]="holiday.start"
               [matDatepicker]="endDatePicker"
               placeholder="End Date">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <button type="submit" [disabled]="!form.form.valid" mat-button>Save</button>
      <button type="button" *ngIf="!isNew" color="warn" (click)="deleteHoliday()" mat-button>Delete</button>
    </mat-card-actions>
  </mat-card>
</form>
