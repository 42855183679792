<ng-container *appToolbarContent>
  <button *ngIf="!isNew && isDev && ('impersonate' | isUserPolicy)" (click)="impersonate()"
          mat-button>
    <mat-icon mat-list-icon>face</mat-icon>
    Impersonate
  </button>
</ng-container>

<div class="main-card">
  <mat-card>
    <form #userForm="ngForm" class="two-colum-grid" id="userForm" (ngSubmit)="saveUser()">

      <mat-form-field>
        <input matInput required [disabled]="isSelf" name="name" [(ngModel)]="user.userName" placeholder="Username"
               i18n-placeholder="username input field@@username">
      </mat-form-field>
      <span></span>
      <mat-form-field>
        <input matInput required name="email" [(ngModel)]="user.email" type="email" email #emailElement="ngModel"
               placeholder="Email" i18n-placeholder="user email address@@email">
        <mat-error *ngIf="emailElement.invalid && (emailElement.dirty || emailElement.touched)"
                   i18n="email validation error@@emailValidation">
          Email invalid
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput name="phoneNumber" [(ngModel)]="user.phoneNumber" type="tel"
               placeholder="Phone Number" i18n-placeholder="user phone number@@phoneNumber">
      </mat-form-field>
      <mat-form-field>
        <input matInput [required]="isNew" #passwordElement="ngModel" name="password" minlength="8" autocomplete="off"
               [(ngModel)]="password" type="password"
               placeholder="Password"
               i18n-placeholder="user password@@password">
        <mat-error *ngIf="passwordElement.invalid && (passwordElement.dirty || passwordElement.touched)"
                   i18n="password error@@passwordLengthWrong">
          Password must be at least 8 characters long
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput [required]="isNew || (passwordElement.value != '' && passwordElement.value)"
               #passwordElement2="ngModel" name="password2" minlength="8" autocomplete="off"
               ngModel
               [pattern]="passwordElement.value"
               type="password"
               placeholder="Confirm Password"
               i18n-placeholder="user password confirm@@passwordConfirm">
        <mat-error
          *ngIf="passwordElement2.invalid && (passwordElement2.dirty || passwordElement2.touched || passwordElement.touched)"
          i18n="password matching error@@passwordsMustMatch">
          Passwords must match
        </mat-error>
      </mat-form-field>
      <ng-container *ngIf="'userManager' | isUserPolicy">
        <mat-slide-toggle name="resetPassword"
                          [(ngModel)]="user.resetPassword"
                          matTooltip="Next time a user logs in with a password, they will be required to change it">
          Require password reset
        </mat-slide-toggle>
        <mat-form-field>
          <mat-select name="person"
                      [(ngModel)]="user.personId"
                      placeholder="Person">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let eachPerson of people | async" [value]="eachPerson.id">{{eachPerson.preferredName}}
              {{eachPerson.lastName}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-select name="roles"
                      multiple
                      [ngModel]="user.roles"
                      (ngModelChange)="updateRoles($event)"
                      placeholder="Roles">
            <mat-option value="admin">Admin</mat-option>
            <mat-option value="hr">HR</mat-option>
            <mat-option value="hradmin">HR Admin</mat-option>
            <mat-option value="registrar">Registrar</mat-option>
          </mat-select>

        </mat-form-field>
        <mat-slide-toggle name="hrNotifications"
                          [(ngModel)]="user.sendHrLeaveEmails">
          Send HR Leave emails
        </mat-slide-toggle>
      </ng-container>


      <mat-error *ngIf="errorMessage">
        {{errorMessage}}
      </mat-error>
    </form>
    <mat-card-actions>
      <button type="submit" form="userForm" [disabled]="!userForm.form.valid" mat-button>
        <ng-container *ngIf="isNew; then register else save">
        </ng-container>
        <ng-template #save i18n="save@@save">Save</ng-template>
        <ng-template #register i18n="register@@register">Register</ng-template>
      </button>
      <button mat-button *ngIf="!isNew" type="button" (click)="deleteUser()" color="warn">Delete</button>
    </mat-card-actions>
  </mat-card>
</div>



