/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "./message.component";
import * as i5 from "@angular/router";
var styles_MessageComponent = [i0.styles];
var RenderType_MessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageComponent, data: {} });
export { RenderType_MessageComponent as RenderType_MessageComponent };
export function View_MessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "main-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-card", [["class", "mat-card"]], null, null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(2, 49152, null, 0, i3.MatCard, [], null, null), (_l()(), i1.ɵted(3, 0, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 3, 0, currVal_0); }); }
export function View_MessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message", [], null, null, null, View_MessageComponent_0, RenderType_MessageComponent)), i1.ɵdid(1, 114688, null, 0, i4.MessageComponent, [i5.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageComponentNgFactory = i1.ɵccf("app-message", i4.MessageComponent, View_MessageComponent_Host_0, {}, {}, []);
export { MessageComponentNgFactory as MessageComponentNgFactory };
