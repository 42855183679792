import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class EndorsementService {
    constructor(http) {
        this.http = http;
    }
    list() {
        return this.http.get('/api/endorsement');
    }
    getEndorsementById(id) {
        return this.http.get('/api/endorsement/' + id);
    }
    saveEndorsement(endorsement) {
        return this.http.post('/api/endorsement', endorsement);
    }
    deleteEndorsement(id) {
        return this.http.delete('/api/endorsement/' + id, { responseType: 'text' });
    }
    saveRequiredEndorsement(requiredEndorsement) {
        return this.http.post('/api/endorsement/required', requiredEndorsement).toPromise();
    }
    deleteRequiredEndorsement(id) {
        return this.http.delete('/api/endorsement/required/' + id, { responseType: 'text' }).toPromise();
    }
    saveStaffEndorsement(staffEndorsement) {
        return this.http.post('/api/endorsement/staff', staffEndorsement).toPromise();
    }
    deleteStaffEndorsement(id) {
        return this.http.delete('/api/endorsement/staff/' + id, { responseType: 'text' }).toPromise();
    }
}
EndorsementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EndorsementService_Factory() { return new EndorsementService(i0.ɵɵinject(i1.HttpClient)); }, token: EndorsementService, providedIn: "root" });
