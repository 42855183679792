import { Holiday } from '../people/leave-request/holiday';
import { HolidayService } from './holiday.service';
import * as i0 from "@angular/core";
import * as i1 from "./holiday.service";
export class HolidayResolverService {
    constructor(holidayService) {
        this.holidayService = holidayService;
    }
    resolve(route, state) {
        if (route.params['id'] == 'new')
            return new Holiday();
        return this.holidayService.getHolidayById(route.params['id']);
    }
}
HolidayResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HolidayResolverService_Factory() { return new HolidayResolverService(i0.ɵɵinject(i1.HolidayService)); }, token: HolidayResolverService, providedIn: "root" });
