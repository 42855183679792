/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./quick-add-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "@angular/material/tooltip";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "@angular/cdk/scrolling";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "@angular/platform-browser";
import * as i12 from "./quick-add.directive";
import * as i13 from "@angular/material/dialog";
import * as i14 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i15 from "@angular/material/icon";
import * as i16 from "@angular/router";
import * as i17 from "@angular/common";
import * as i18 from "./quick-add-button.component";
var styles_QuickAddButtonComponent = [i0.styles];
var RenderType_QuickAddButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_QuickAddButtonComponent, data: {} });
export { RenderType_QuickAddButtonComponent as RenderType_QuickAddButtonComponent };
function View_QuickAddButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 6, "button", [["appQuickAdd", ""], ["mat-icon-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "updateSelected"], [null, "updateList"], [null, "longpress"], [null, "keydown"], [null, "touchend"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_3 && ad);
    } if (("updateSelected" === en)) {
        var pd_4 = (_co.personIdChange.emit($event) !== false);
        ad = (pd_4 && ad);
    } if (("updateList" === en)) {
        var pd_5 = (_co.updateList.emit($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 212992, null, 0, i6.MatTooltip, [i7.Overlay, i1.ElementRef, i8.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i9.Platform, i4.AriaDescriber, i4.FocusMonitor, i6.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i10.Directionality], [2, i6.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i11.HAMMER_LOADER]], { message: [0, "message"] }, null), i1.ɵdid(3, 16384, null, 0, i12.QuickAddDirective, [i13.MatDialog], null, { updateList: "updateList", updateSelected: "updateSelected" }), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i14.View_MatIcon_0, i14.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i15.MatIcon, [i1.ElementRef, i15.MatIconRegistry, [8, null], [2, i15.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["person_add"])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "Quick-Add ", _co.caption, ""); _ck(_v, 2, 0, currVal_2); _ck(_v, 5, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 5).inline; var currVal_4 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_3, currVal_4); }); }
function View_QuickAddButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 6, "a", [["mat-icon-button", ""]], [[1, "target", 0], [8, "href", 4], [1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._haltDisabledEvents($event) !== false);
        ad = (pd_1 && ad);
    } if (("longpress" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).show() !== false);
        ad = (pd_2 && ad);
    } if (("keydown" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_3 && ad);
    } if (("touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 3)._handleTouchend() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_MatAnchor_0, i2.RenderType_MatAnchor)), i1.ɵdid(1, 671744, null, 0, i16.RouterLinkWithHref, [i16.Router, i16.ActivatedRoute, i17.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 180224, null, 0, i3.MatAnchor, [i4.FocusMonitor, i1.ElementRef, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(3, 212992, null, 0, i6.MatTooltip, [i7.Overlay, i1.ElementRef, i8.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i9.Platform, i4.AriaDescriber, i4.FocusMonitor, i6.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i10.Directionality], [2, i6.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i11.HAMMER_LOADER]], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i14.View_MatIcon_0, i14.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i15.MatIcon, [i1.ElementRef, i15.MatIconRegistry, [8, null], [2, i15.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["person"])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_6 = ("/people/edit/" + _co.personId); _ck(_v, 1, 0, currVal_6); var currVal_7 = ("View " + _co.caption); _ck(_v, 3, 0, currVal_7); _ck(_v, 5, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; var currVal_2 = (i1.ɵnov(_v, 2).disabled ? (0 - 1) : (i1.ɵnov(_v, 2).tabIndex || 0)); var currVal_3 = (i1.ɵnov(_v, 2).disabled || null); var currVal_4 = i1.ɵnov(_v, 2).disabled.toString(); var currVal_5 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_8 = i1.ɵnov(_v, 5).inline; var currVal_9 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_8, currVal_9); }); }
export function View_QuickAddButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuickAddButtonComponent_1)), i1.ɵdid(1, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuickAddButtonComponent_2)), i1.ɵdid(3, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.personId; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.personId; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_QuickAddButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-quick-add-button", [], null, null, null, View_QuickAddButtonComponent_0, RenderType_QuickAddButtonComponent)), i1.ɵdid(1, 114688, null, 0, i18.QuickAddButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuickAddButtonComponentNgFactory = i1.ɵccf("app-quick-add-button", i18.QuickAddButtonComponent, View_QuickAddButtonComponent_Host_0, { caption: "caption", personId: "personId" }, { personIdChange: "personIdChange", updateList: "updateList" }, []);
export { QuickAddButtonComponentNgFactory as QuickAddButtonComponentNgFactory };
