<ng-container *appTemplateContent="'title'">Home</ng-container>
<ng-container *appToolbarContent>
</ng-container>

<div class="home">
  <mat-card class="mat-elevation-z6" *ngIf="'hrEdit' | isUserPolicy">
    <!--<img mat-card-image src="assets/img/believe-home.jpg" alt="Believe">-->
    <mat-card-title>HR</mat-card-title>
    <mat-card-content>
      <mat-nav-list>
        <ng-container appRenderTemplate="sidenav:hr"></ng-container>
      </mat-nav-list>
    </mat-card-content>
  </mat-card>

  <mat-card class="mat-elevation-z6" *ngIf="'hrEdit' | isUserPolicy">
    <!--<img mat-card-image src="assets/img/believe-home.jpg" alt="Believe">-->
    <mat-card-title>School Aide</mat-card-title>
    <mat-card-content>
      <mat-nav-list>
        <ng-container appRenderTemplate="sidenav:school-aid"></ng-container>
      </mat-nav-list>
    </mat-card-content>
  </mat-card>

  <mat-card class="mat-elevation-z6">
    <!--<img mat-card-image src="assets/img/pexels-photo-592940.jpeg" alt="Live">-->
    <mat-card-title>Me!</mat-card-title>
    <mat-card-content>
      <mat-nav-list>
        <ng-container appRenderTemplate="sidenav:me"></ng-container>
      </mat-nav-list>
    </mat-card-content>
  </mat-card>
  <mat-card class="mat-elevation-z6" *ngIf="'registrarEdit' | isUserPolicy">
    <!--<img mat-card-image src="assets/img/pexels-photo-592940.jpeg" alt="Live">-->
    <mat-card-title>Registrar</mat-card-title>
    <mat-card-content>
      <mat-nav-list>
        <ng-container appRenderTemplate="sidenav:registrar"></ng-container>
      </mat-nav-list>
    </mat-card-content>
  </mat-card>
</div>
