import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LeaveRequestService } from './leave-request.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../../services/auth/login.service';
import { combineLatest } from 'rxjs';
import { PersonService } from '../person.service';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
import { LeaveType } from '../self/self';
import { Gender } from '../person';
import { BaseEditComponent } from '../../components/base-edit-component';
import { HolidayService } from '../../holiday/holiday.service';
export class LeaveRequestComponent extends BaseEditComponent {
    constructor(route, router, leaveRequestService, snackBar, loginService, personService, location, holidayService, dialog) {
        super(dialog);
        this.route = route;
        this.router = router;
        this.leaveRequestService = leaveRequestService;
        this.snackBar = snackBar;
        this.loginService = loginService;
        this.personService = personService;
        this.location = location;
        this.holidayService = holidayService;
        this.typesOfLeave = Object.keys(LeaveType);
        this.daysUsed = 0;
        this.isHr = false;
        this.sendNotification = true;
        this.noNotificationSnackbarRef = null;
        this.holidays = [];
        this.intersectingHolidays = '';
    }
    get isReadonly() {
        return this.leaveRequest.approved !== null && !this.isHr;
    }
    ngOnInit() {
        this.subscription = combineLatest(this.route.data, this.loginService.safeUserToken(), this.route.queryParams)
            .subscribe(([data, user, queryParams]) => {
            this.people = data.people;
            this.leaveRequest = data.leaveRequest;
            this.isNew = !this.leaveRequest.id;
            this.isHr = user.isHrOrAdmin();
            this.updateDaysUsed();
            this.sendNotification = (!this.isHr || (!queryParams.noNotification && this.isHr));
            if (!this.sendNotification && this.isNew) {
                setTimeout(() => this.warnNoLeaveNotification());
            }
            this.myPersonId = user.personId;
            const person = this.people.find(p => p.person.id === (this.leaveRequest.personId || user.personId));
            if (person) {
                this.leaveRequest.personId = person.person.id;
                this.selectedPerson = person;
            }
        });
        this.holidayService.currentHolidays().subscribe(holidays => {
            this.holidays = holidays;
            this.updateDaysUsed();
        });
    }
    warnNoLeaveNotification(sendNotification = false) {
        if (sendNotification) {
            if (this.noNotificationSnackbarRef)
                this.noNotificationSnackbarRef.dismiss();
            return;
        }
        this.noNotificationSnackbarRef = this.snackBar.open(`Approval Emails won't be sent for this leave request`, 'Dismiss');
    }
    updateDaysUsed() {
        if ((this.leaveRequest.startDate && !this.leaveRequest.endDate) || this.leaveRequestService.isStartAfterEnd(this.leaveRequest)) {
            this.leaveRequest.endDate = this.leaveRequest.startDate;
        }
        if (this.leaveRequest && !this.leaveRequest.approved && !this.leaveRequest.overrideDays && this.leaveRequest.startDate && this.leaveRequest.endDate) {
            let result = this.leaveRequestService.weekDays(this.leaveRequest, this.holidays);
            this.leaveRequest.days = result.days;
            this.intersectingHolidays = result.countedHolidays;
        }
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    submit(isDraft = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.isReadonly)
                return;
            if (isDraft)
                this.formSubmitted = true;
            if (this.isNew && !(yield this.promptNewRequest())) {
                return;
            }
            if (!isDraft && this.sendNotification) {
                yield this.submitLeaveRequest();
            }
            else {
                yield this.saveRequest(isDraft);
            }
        });
    }
    promptNewRequest() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let overUsingLeave = this.leaveRequestService.isOverUsingLeave(this.leaveRequest, this.selectedPerson.leaveUsages);
            let doctorsNote = this.leaveRequest.type == LeaveType.Sick && (this.leaveRequest.days > 2);
            if (overUsingLeave || doctorsNote) {
                return yield ConfirmDialogComponent.OpenWait(this.dialog, (overUsingLeave ? `This leave request is using more leave than you have\n` : '')
                    + (doctorsNote ? `You must email a doctors note to HR` : ''), 'Request Leave', 'Cancel');
            }
            return true;
        });
    }
    submitLeaveRequest() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const notified = yield this.leaveRequestService.requestLeave(this.leaveRequest);
            let message;
            if (!notified) {
                message = `Leave request ${this.isNew ? 'created' : 'updated'}, supervisor not found, no notification was sent`;
            }
            else {
                message = `Leave request ${this.isNew ?
                    'created' :
                    'updated'}, notified ${notified.firstName} ${notified.lastName}`;
            }
            this.snackBar.open(message, 'Dismiss');
            this.router.navigate([
                'leave-request',
                'list',
                this.myPersonId === this.leaveRequest.personId ? 'mine' : this.leaveRequest.personId
            ]);
        });
    }
    saveRequest(isDraft) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.leaveRequestService.updateLeave(this.leaveRequest).toPromise();
            let message = 'Leave updated, notification was not sent of changes';
            if (isDraft)
                message = 'Leave Request draft saved';
            this.snackBar.open(message, null, { duration: 2000 });
            this.location.back();
        });
    }
    promptSendNotification() {
        if (this.leaveRequest.approved)
            return Promise.resolve(false);
        return ConfirmDialogComponent.OpenWait(this.dialog, 'Send another leave request approval email?', 'Send Email & Save', 'Save');
    }
    personSelectedChanged(personId) {
        this.selectedPerson = this.people.find(value => value.person.id === personId);
    }
    overrideDaysChanged(overrideDays) {
        if (!overrideDays) {
            this.updateDaysUsed();
        }
    }
    deleteRequest() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield ConfirmDialogComponent.OpenWait(this.dialog, `Delete Request for ${this.selectedPerson.person.preferredName}?`, 'Delete', 'Cancel');
            if (!result)
                return;
            yield this.leaveRequestService.deleteRequest(this.leaveRequest.id).toPromise();
            this.snackBar.open('Request deleted', null, { duration: 2000 });
            this.location.back();
        });
    }
    showLeaveUsage(leaveUsage) {
        if (!this.showLeaveType(leaveUsage.leaveType))
            return false;
        return leaveUsage.totalAllowed != 0 || leaveUsage.used != 0;
    }
    showLeaveType(leaveType) {
        if (!this.selectedPerson)
            return true;
        switch (leaveType) {
            case LeaveType.Vacation:
                return this.selectedPerson.person.isThai;
            case LeaveType.Paternity:
                return this.selectedPerson.person.gender == Gender.Male;
            case LeaveType.Maternity:
                return this.selectedPerson.person.gender == Gender.Female;
            default:
                return true;
        }
    }
    approvedChanged(approved) {
        if (approved) {
            this.leaveRequest.approvedById = this.myPersonId;
        }
        else {
            this.leaveRequest.approvedById = null;
        }
    }
}
