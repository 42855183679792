<ng-container *appTemplateContent="'title'">Required Training Report</ng-container>
<ng-container *appToolbarContent>
  <mat-slide-toggle name="showCompleted" [ngModel]="showCompleted | async"
                    (ngModelChange)="setShowCompleted($event)">Show Completed
  </mat-slide-toggle>
  <button (click)="setYear(selectedYear.value - 1)" [disabled]="isFirstYear" mat-icon-button>
    <mat-icon>navigate_before</mat-icon>
  </button>

  <mat-form-field style="padding-left: 8px; width: 100px">
    <mat-select [(ngModel)]="selectedYear" (ngModelChange)="setYear($event.value)"
                placeholder="School Year">
      <mat-option *ngFor="let year of years" [value]="year">{{year.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <button (click)="setYear(selectedYear.value + 1)" [disabled]="isLastYear" mat-icon-button>
    <mat-icon>navigate_next</mat-icon>
  </button>
</ng-container>
<div class="training-report">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let reqObj of requirementsWithStaff | async"
                         [expanded]="expandedRequirementId == reqObj.requirement.id"
                         (opened)="expandedRequirementId = reqObj.requirement.id">
      <mat-expansion-panel-header>

        <mat-panel-title>{{reqObj.requirement.name}}</mat-panel-title>
        <mat-panel-description>
          Completed by {{reqObj.completedRequirement}} Staff out of {{reqObj.totalStaff}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-list
          *ngIf="reqObj.completedRequirement != reqObj.totalStaff">
          <mat-list-item>
            <mat-checkbox [checked]="allSelected(reqObj)" [indeterminate]="allSelected(reqObj) == null"
                          (change)="selectAll(reqObj, $event.checked)" style="padding-right: 8px;"></mat-checkbox>
            <button mat-raised-button color="accent"
                    (click)="markSelectedComplete(reqObj)">
              <mat-icon mat-list-icon>done_all</mat-icon>
              Complete Selected
            </button>
            <span style="padding-left: 8px;">
              <ng-container *ngIf="reqObj.requirement.jobScope?.length > 0">
                Job Scope: [
              <ng-container *ngFor="let jobType of reqObj.requirement.jobScope; let last = last">{{(jobType | titleCase) + (last ? '' :', ')}}</ng-container>
                ]
              </ng-container>
              <ng-container *ngIf="reqObj.orgGroup">
                Department: {{reqObj.orgGroup?.groupName}}
              </ng-container>
            </span>
            <span class="spacer"></span>
            <mat-form-field>
              <input name="completedDate" matInput
                     [(ngModel)]="completedDate"
                     [min]="selectedYear.startOfYear"
                     [max]="selectedYear.endOfYear"
                     [matDatepicker]="startDatePicker"
                     placeholder="Date Completed">
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>
          </mat-list-item>
          <mat-divider></mat-divider>

          <ng-container *ngFor="let staffObj of reqObj.staffsWithTraining; let isLast = last; let index = index">
            <mat-list-item>
              <mat-checkbox [disabled]="staffObj.training.completedDate" [(ngModel)]="staffObj.selected"
                            style="padding-right: 8px;"></mat-checkbox>
              <a mat-button [routerLink]="['/people', 'edit', staffObj.staff.personId]">
                <mat-icon>person</mat-icon>
                {{staffObj.staff.preferredName}} {{staffObj.staff.lastName}}
              </a>
              <span class="spacer"></span>
              <button mat-button *ngIf="!staffObj.training.completedDate; else completedElse" color="accent"
                      (click)="completeTraining(reqObj, index)">
                <mat-icon mat-list-icon>check</mat-icon>
                Complete Training
              </button>
              <ng-template #completedElse>
                Completed On {{staffObj.training.completedDate | date}}
              </ng-template>
            </mat-list-item>
            <mat-divider *ngIf="!isLast"></mat-divider>
          </ng-container>

        </mat-list>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>

  <h2 class="empty" *ngIf="(requirementsWithStaff | async)?.length == 0">All staff have completed requirements for
    School
    year {{selectedYear.name}}
    <mat-icon color="accent">thumb_up</mat-icon>
  </h2>

</div>
