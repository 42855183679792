<ng-container *appTemplateContent="'title'">Sending Orgs ({{dataSource.filteredData.length}})</ng-container>
<ng-container *appToolbarContent>
  <button mat-icon-button>
    <mat-icon>search</mat-icon>
  </button>
  <mat-form-field floatLabel="never">
    <input autofocus matInput [(ngModel)]="urlBinding.values.search" placeholder="Search">
  </mat-form-field>
</ng-container>
<mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.name}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="contactName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Contact</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.contactName}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="phone">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Phone</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.phone}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.email}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.status | titleCase}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="approvedDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Approved Date</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.approvedDate | date}}</mat-cell>
  </ng-container>
  <!-- Header and Row Declarations -->
  <mat-header-row
    *matHeaderRowDef="['name', 'contactName', 'phone', 'email', 'status', 'approvedDate']"></mat-header-row>
  <mat-row class="hover-overlay"
           *matRowDef="let row; columns: ['name', 'contactName', 'phone', 'email', 'status', 'approvedDate']"
           [routerLink]="'../edit/' + row.id"></mat-row>
</mat-table>


<a routerLink="../edit/new" class="add-button" mat-fab matTooltip="Add new Sending Org" matTooltipPosition="left">
  <mat-icon>add</mat-icon>
</a>
