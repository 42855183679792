import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from './user.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../dialog/confirm-dialog/confirm-dialog.component';
import { PersonService } from '../people/person.service';
import { environment } from '../../environments/environment';
import { AuthenticateService } from '../services/auth/authenticate.service';
import { BaseEditComponent } from '../components/base-edit-component';
import { Location } from '@angular/common';
export class UserComponent extends BaseEditComponent {
    constructor(route, userService, authService, router, location, dialog, personService, snackBar) {
        super(dialog);
        this.route = route;
        this.userService = userService;
        this.authService = authService;
        this.router = router;
        this.location = location;
        this.personService = personService;
        this.snackBar = snackBar;
        this.isDev = !environment.production;
        this.people = this.personService.getAll();
    }
    ngOnInit() {
        this.route.data.subscribe((data) => {
            this.user = data.user;
            this.isNew = data.isNew;
            this.isSelf = data.isSelf;
        });
    }
    saveUser() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.userService.saveUser(this.user, this.password, this.isNew, this.isSelf);
            this.router.navigate([this.isSelf ? '/home' : '/user/admin']);
        });
    }
    updateRoles(roles) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (roles.includes('hradmin') && !roles.includes('hr'))
                roles.push('hr');
            for (const newRole of roles) {
                if (this.user.roles.includes(newRole))
                    continue;
                yield this.grantRole(newRole);
            }
            const existingRoles = this.user.roles;
            for (const oldRole of existingRoles) {
                if (roles.includes(oldRole))
                    continue;
                yield this.revokeRole(oldRole);
            }
        });
    }
    grantRole(role) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.userService.grantRole(role, this.user.id);
            this.user.roles = [...this.user.roles, role];
            this.snackBar.open(`Role granted, user must logout and login to use new role`, null, { duration: 2000 });
        });
    }
    revokeRole(role) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.userService.revokeRole(role, this.user.id);
            this.user.roles = this.user.roles.filter(value => value != role);
            this.snackBar.open(`Role revoked, user must logout and login to lose the role`, null, { duration: 2000 });
        });
    }
    deleteUser() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let result = yield ConfirmDialogComponent.OpenWait(this.dialog, `Delete User ${this.user.userName}?`, 'Delete', 'Cancel');
            if (result) {
                yield this.userService.deleteUser(this.user.id);
                this.router.navigate(['/user/admin']);
            }
        });
    }
    impersonate() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.authService.impersonate(this.user.email);
            this.snackBar.open(`You're now logged in as ${this.user.userName} just logout to stop impersonating them`, null, { duration: 2000 });
            yield this.router.navigate(['/home']);
            this.location.go(this.location.path());
        });
    }
}
