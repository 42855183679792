import { LoginService } from './login.service';
import * as i0 from "@angular/core";
import * as i1 from "./login.service";
export class PolicyService {
    constructor(loginService) {
        this.loginService = loginService;
        this.policies = {
            viewSalary: (user) => user.hasAnyRole(['admin', 'hradmin']),
            orgGroupManager: (user) => user.hasAnyRole(['admin', 'hr']) || user.isHigherup,
            orgChart: (user) => user.hasAnyRole(['admin', 'hr']) || user.isSupervisor,
            leaveManager: (user) => user.hasAnyRole(['admin', 'hr']) || user.isHigherup,
            staffLeaveManager: (user) => user.isHrOrAdmin() || user.isLeaveDelegate || user.isSupervisor,
            leaveSupervisor: (user) => user.isLeaveDelegate || user.isSupervisor,
            isSupervisor: (user) => user.isSupervisor,
            endorsementManager: (user) => user.hasAnyRole(['admin', 'hr']) || user.isHigherup,
            peopleEdit: (user) => user.hasAnyRole(['admin', 'hr', 'registrar']) || user.isSupervisor,
            missionOrgManager: (user) => user.hasAnyRole(['admin', 'hr', 'registrar']) || user.isHigherup,
            hrEdit: (user) => user.isHrOrAdmin() || user.isHigherup,
            addRoles: (user) => user.isHrOrAdmin() || user.isHigherup,
            addEvaluations: (user) => user.isHrOrAdmin() || user.isHigherup,
            addTraining: (user) => user.isHrOrAdmin() || user.isHigherup,
            registrarEdit: (user) => user.hasAnyRole(['admin', 'registrar']),
            userManager: (user) => user.hasRole('admin'),
            impersonate: (user) => user.hasRole('admin')
        };
    }
    getPolicy(policy) {
        return this.policies[policy];
    }
    testPolicy(policy) {
        let policyFunction = this.getPolicy(policy);
        return policyFunction && policyFunction(this.loginService.userToken);
    }
}
PolicyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PolicyService_Factory() { return new PolicyService(i0.ɵɵinject(i1.LoginService)); }, token: PolicyService, providedIn: "root" });
