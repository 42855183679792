﻿
<div class="main">
  <mat-toolbar color="primary">
    <button id="open-menu-button" mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span #titleElement (cdkObserveContent)="updateTitle()">GIS
      <ng-container appRenderTemplate="title" (onHasView)="hasTitle = $event"></ng-container>
    </span>
    <span class="spacer"></span>
    <ng-container appRenderTemplate></ng-container>
    <button *ngIf="hasAttachments | async"
            mat-icon-button
            matTooltip="Attachments"
            matTooltipPosition="left"
            (click)="rightDrawer.toggle()">
      <mat-icon>attach_file</mat-icon>
    </button>
  </mat-toolbar>
  <mat-progress-bar class="activity-indicator"
                    color="accent"
                    mode="indeterminate"
                    [hidden]="!(indicatorStatus | async)">
  </mat-progress-bar>
  <mat-sidenav-container>
    <mat-sidenav position="start" class="nav-drawer" #sidenav>
      <mat-nav-list>
        <div id="user" *ngIf="currentUser | async as user">
          <h2>
            <ng-container i18n="welcome user@@welcomeUser">Welcome {{user.userName}}</ng-container>

          </h2>
          <button mat-button tabindex="-1" (click)="logout()">
            Logout
          </button>
        </div>

        <a mat-list-item routerLink="/home" routerLinkActive="active-link">
          <mat-icon mat-list-icon>home</mat-icon>
          <a mat-line i18n="take user to home page link@@homeLink">Home</a>
        </a>
        <ng-container *ngIf="currentUser|async as user">

          <mat-divider></mat-divider>
          <h3 mat-subheader>Me!</h3>
          <ng-container appRenderTemplate="sidenav:me"></ng-container>
          <ng-container *appTemplateContent="'sidenav:me'">
            <a mat-list-item routerLink="/leave-request/edit/new" routerLinkActive="active-link">
              <mat-icon mat-list-icon>transfer_within_a_station</mat-icon>
              <p mat-line>Request Leave</p>
            </a>
            <a mat-list-item routerLink="/leave-request/list/mine" routerLinkActive="active-link">
              <mat-icon mat-list-icon>transfer_within_a_station</mat-icon>
              <span mat-line>My Leave</span>
            </a>
            <a mat-list-item routerLink="/people/staff/self" routerLinkActive="active-link">
              <mat-icon mat-list-icon>face</mat-icon>
              <span mat-line>My Details</span>
            </a>
            <a *ngIf="user.isSupervisor" mat-list-item [routerLink]="['/org-tree', user.orgGroupId]"
               routerLinkActive="active-link">
              <mat-icon mat-list-icon>location_city</mat-icon>
              <span mat-line>My Org Chart</span>
            </a>
            <a *ngIf="user.isSupervisor" mat-list-item routerLink="/people/staff/report/supervisor"
               routerLinkActive="active-link">
              <mat-icon mat-list-icon>business</mat-icon>
              <span mat-line>My Staff report</span>
            </a>
            <a *ngIf="user.isSupervisor" mat-list-item routerLink="/job/report/roles/supervisor"
               routerLinkActive="active-link">
              <mat-icon mat-list-icon>work</mat-icon>
              <span mat-line>My Roles report</span>
            </a>
            <a *ngIf="user.isSupervisor || user.isLeaveDelegate"
               mat-list-item
               routerLink="/leave-request/report/supervisor"
               routerLinkActive="active-link">
              <mat-icon mat-list-icon>transfer_within_a_station</mat-icon>
              <span mat-line>My People's Leave</span>
            </a>
            <a *ngIf="'leaveSupervisor' | isUserPolicy"
               mat-list-item
               routerLink="/calendar/supervisor"
               routerLinkActive="active-link">
              <mat-icon mat-list-icon>date_rangeff</mat-icon>
              <span mat-line>Leave Calendar</span>
            </a>
            <a
              mat-list-item
              routerLink="/calendar/public"
              routerLinkActive="active-link">
              <mat-icon mat-list-icon>date_rangeff</mat-icon>
              <span mat-line>Public Leave Calendar</span>
            </a>
          </ng-container>

          <ng-container *ngIf="'hrEdit' | isUserPolicy">
            <mat-divider></mat-divider>
            <h3 mat-subheader>HR</h3>
            <ng-container appRenderTemplate="sidenav:hr"></ng-container>
            <ng-container *appTemplateContent="'sidenav:hr'">
              <a mat-list-item routerLink="/calendar/all" routerLinkActive="active-link">
                <mat-icon mat-list-icon>date_range</mat-icon>
                <span mat-line>Calendar</span>
              </a>
              <a mat-list-item routerLink="/people/staff/report" routerLinkActive="active-link">
                <mat-icon mat-list-icon>business</mat-icon>
                <span mat-line>Staff</span>
              </a>
              <a mat-list-item routerLink="/job/report/roles" routerLinkActive="active-link">
                <mat-icon mat-list-icon>work</mat-icon>
                <span mat-line>Roles</span>
              </a>
              <a mat-list-item routerLink="/endorsement/list" routerLinkActive="active-link">
                <mat-icon mat-list-icon>school</mat-icon>
                <span mat-line>Certifications</span>
              </a>
              <a mat-list-item routerLink="/people/report/evaluations" routerLinkActive="active-link">
                <mat-icon mat-list-icon>assignment_ind</mat-icon>
                <span mat-line>Evaluations</span>
              </a>
              <a mat-list-item routerLink="/leave-request/report/all" routerLinkActive="active-link">
                <mat-icon mat-list-icon>transfer_within_a_station</mat-icon>
                <span mat-line>Leave</span>
              </a>
              <a mat-list-item routerLink="/holiday/list" routerLinkActive="active-link">
                <mat-icon mat-list-icon>local_florist</mat-icon>
                <span mat-line>Leave Holidays</span>
              </a>
              <a mat-list-item routerLink="/training/list" routerLinkActive="active-link">
                <mat-icon mat-list-icon>school</mat-icon>
                <span mat-line>Training</span>
              </a>
              <mat-divider></mat-divider>

              <a mat-list-item
                 [routerLink]="['/job','list']"
                 [queryParams]="{status: nonSchoolAidJobStatus}"
                 [class.active-link]="isLinkActive('/job/list')">
                <mat-icon mat-list-icon>work</mat-icon>
                <span mat-line>Jobs</span>
              </a>
              <a mat-list-item *ngIf="'orgChart' | isUserPolicy" routerLink="/org-tree/all"
                 routerLinkActive="active-link">
                <mat-icon mat-list-icon>location_city</mat-icon>
                <span mat-line>Organization</span>
              </a>
              <mat-divider></mat-divider>

              <a mat-list-item routerLink="/people/list" routerLinkActive="active-link">
                <mat-icon mat-list-icon>supervisor_account</mat-icon>
                <span mat-line>People</span>
              </a>
              <a mat-list-item routerLink="/mission-org/list" routerLinkActive="active-link">
                <mat-icon mat-list-icon>account_balance</mat-icon>
                <span mat-line>Sending Org</span>
              </a>
            </ng-container>

            <mat-divider></mat-divider>
            <h3 mat-subheader>School Aide</h3>
            <ng-container appRenderTemplate="sidenav:school-aid"></ng-container>
            <ng-container *appTemplateContent="'sidenav:school-aid'">
              <a mat-list-item
                 [routerLink]="['/job','list']"
                 [queryParams]="{status: [schoolAidJobStatus]}"
                 [class.active-link]="isLinkActive('/job/list')">
                <mat-icon mat-list-icon>work</mat-icon>
                <span mat-line>Jobs</span>
              </a>
              <a mat-list-item routerLink="/job/report/roles" [queryParams]="{status: [schoolAidJobStatus]}"
                 routerLinkActive="active-link">
                <mat-icon mat-list-icon>work</mat-icon>
                <span mat-line>Roles</span>
              </a>
              <a mat-list-item routerLink="/people/school-aid/list" routerLinkActive="active-link">
                <mat-icon mat-list-icon>supervisor_account</mat-icon>
                <span mat-line>School Aids</span>
              </a>
            </ng-container>

          </ng-container>

          <ng-container *ngIf="'registrarEdit' | isUserPolicy">
            <mat-divider></mat-divider>
            <h3 mat-subheader>Registrar</h3>
            <ng-container appRenderTemplate="sidenav:registrar"></ng-container>
            <ng-container *appTemplateContent="'sidenav:registrar'">
              <a mat-list-item routerLink="/mission-org/list" routerLinkActive="active-link">
                <mat-icon mat-list-icon>account_balance</mat-icon>
                <span mat-line>Sending Org</span>
              </a>
              <a mat-list-item routerLink="/people/list" routerLinkActive="active-link">
                <mat-icon mat-list-icon>supervisor_account</mat-icon>
                <span mat-line>People</span>
              </a>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="'userManager' | isUserPolicy">
            <mat-divider></mat-divider>
            <h3 mat-subheader>Admin</h3>
            <a mat-list-item routerLink="/user/admin" routerLinkActive="active-link">
              <mat-icon mat-list-icon>face</mat-icon>
              <a mat-line>User Managment</a>
            </a>
            <a mat-list-item *ngIf="isDev" routerLink="/sandbox" routerLinkActive="active-link">Sandbox</a>
          </ng-container>
        </ng-container>
        <mat-divider></mat-divider>

      </mat-nav-list>
      <span mat-subheader>
        Version: {{version}}&nbsp;
        <a class="mat-link" href="https://github.com/hahn-kev/gis">About Application</a>
      </span>
    </mat-sidenav>
    <mat-sidenav class="mat-elevation-z2" mode="side" position="end" #rightDrawer>
      <app-attachments *ngIf="rightDrawer.opened"></app-attachments>
    </mat-sidenav>
    <router-outlet></router-outlet>
  </mat-sidenav-container>

</div>
