import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MissionOrgStatus } from '../mission-org';
import { MissionOrgService } from '../mission-org.service';
import { BaseEditComponent } from '../../components/base-edit-component';
import { MissionOrgLevel, MissionOrgYearSummary } from '../mission-org-year-summary';
import { Year } from '../../people/training-requirement/year';
import { RenderTemplateBottomSheetComponent } from '../../components/render-template-bottom-sheet/render-template-bottom-sheet.component';
export class MissionOrgComponent extends BaseEditComponent {
    constructor(missionOrgService, route, router, snackBar, bottomSheet, dialog) {
        super(dialog);
        this.missionOrgService = missionOrgService;
        this.route = route;
        this.router = router;
        this.snackBar = snackBar;
        this.bottomSheet = bottomSheet;
        this.schoolYears = Year.years();
        this.statusList = Object.keys(MissionOrgStatus);
        this.levelList = Object.keys(MissionOrgLevel);
        this.isNew = false;
        this.createNewYear = () => {
            let yearSummary = new MissionOrgYearSummary();
            yearSummary.missionOrgId = this.missionOrg.id;
            return yearSummary;
        };
        this.deleteYear = (yearSummary) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.missionOrgService.deleteYear(yearSummary.id);
        });
        this.saveYear = (yearSummary) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            return Object.assign({}, yearSummary, yield this.missionOrgService.saveYear(yearSummary));
        });
    }
    ngOnInit() {
        this.route.data.subscribe((value) => {
            this.missionOrg = value.missionOrg;
            this.people = value.people;
            this.isNew = !this.missionOrg.id;
        });
    }
    save() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.missionOrgService.save(this.missionOrg);
            this.router.navigate(['/mission-org/list']);
            this.snackBar.open(`${this.missionOrg.name} ${this.isNew ? 'Added' : 'Saved'}`, null, { duration: 2000 });
        });
    }
    delete() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let result = yield ConfirmDialogComponent.OpenWait(this.dialog, `Delete Sending Org ${this.missionOrg.name}?`, 'Delete', 'Cancel');
            if (!result)
                return;
            yield this.missionOrgService.delete(this.missionOrg.id);
            this.router.navigate(['/mission-org/list']);
            this.snackBar.open(`${this.missionOrg.name} Deleted`, null, { duration: 2000 });
        });
    }
    showStaff() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.isNew) {
                this.peopleInOrg = [];
            }
            else if (!this.peopleInOrg) {
                this.peopleInOrg = yield this.missionOrgService.listPeople(this.missionOrg.id).toPromise();
            }
            RenderTemplateBottomSheetComponent.Open(this.bottomSheet, 'staff');
        });
    }
}
