<ng-container *appTemplateContent="'title'">Users ({{dataSource.filteredData.length}})</ng-container>
<ng-container *appToolbarContent>
  <button mat-icon-button>
  </button>
  <mat-form-field appearance="fill" class="print-hide">
    <mat-icon matPrefix="">search</mat-icon>
    <mat-label>Search</mat-label>
    <input autofocus matInput
           [value]="urlBinding.values.search"
           (keyup)="urlBinding.values.search = $event.target.value"
           placeholder="Search">
  </mat-form-field>
  <app-export-button [values]="dataSource" fileName="peopleData"></app-export-button>
</ng-container>
<mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="userName">
    <mat-header-cell *matHeaderCellDef>User Name</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.userName }}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="personName">
    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.personName}}</mat-cell>
  </ng-container>
  <!-- Header and Row Declarations -->
  <mat-header-row *matHeaderRowDef="['userName', 'personName']"></mat-header-row>
  <mat-row class="hover-overlay" *matRowDef="let row; columns: ['userName', 'personName']"
           [routerLink]="'/user/edit/' + row.userName"></mat-row>
</mat-table>

<a routerLink="/user/edit/new" class="user-add" mat-fab matTooltip="Add new User" matTooltipPosition="left">
  <mat-icon>add</mat-icon>
</a>
