<ng-container *appTemplateContent="'title'">Edit {{group.type}}</ng-container>
<ng-container *appToolbarContent>
  <a mat-icon-button *ngIf="!isNew" [routerLink]="['/org-tree', group.id]"
     matTooltip="View Org Tree At {{group.groupName}}">
    <mat-icon>location_city</mat-icon>
  </a>
  <a mat-icon-button *ngIf="group.supervisor" [routerLink]="['/leave-request', 'list', 'supervisor', group.supervisor]">
    <mat-icon>transfer_within_a_station</mat-icon>
  </a>
</ng-container>
<form class="main-card" id="form" #groupForm="ngForm" (ngSubmit)="save()">
  <mat-card class="two-colum-grid">
    <mat-form-field>
      <input matInput required name="groupName" [(ngModel)]="group.groupName" placeholder="Name">
    </mat-form-field>
    <mat-form-field>
      <mat-select required name="type" [(ngModel)]="group.type" placeholder="Type">
        <mat-option value="Division">Division</mat-option>
        <mat-option value="Department">Department</mat-option>
        <mat-option value="Supervisor">Office</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-select name="supervisor" [ngModel]="group.supervisor" (ngModelChange)="supervisorChanged($event)"
                  placeholder="Supervisor">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let eachPerson of people" [value]="eachPerson.id">{{eachPerson.preferredName}}
          {{eachPerson.lastName}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-slide-toggle name="approver" [(ngModel)]="group.approverIsSupervisor">Supervisor approves Leave
    </mat-slide-toggle>
    <mat-form-field>
      <mat-select name="parent" [(ngModel)]="group.parentId" (ngModelChange)="refreshOrgChain()"
                  placeholder="Parent Group">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let eachGroup of groups" [value]="eachGroup.id">{{eachGroup.groupName}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="org-chain span-2-columns" *ngIf="orgChain">
      <ng-container *ngFor="let link of orgChain.links; last as isLast">
        <a mat-button [disabled]="!link.id"
           [routerLink]="'/' + (link.type) + '/edit/' + link.id">{{link.title}}</a>
        <mat-icon *ngIf="!isLast">arrow_forward</mat-icon>
      </ng-container>
    </div>

    <mat-card-actions>
      <button type="submit"
              form="form"
              [disabled]="!groupForm.form.valid || disabledDueToDuplicateSupervisor"
              mat-button>Save
      </button>
      <button type="button" *ngIf="!isNew" color="warn" (click)="deleteGroup()" mat-button>Delete</button>
    </mat-card-actions>
  </mat-card>
  <div>
    <span class="mat-caption">Children</span>
    <mat-card>
      <mat-card-content>
        <mat-nav-list style="display: flex; flex-wrap: wrap">
          <a mat-list-item *ngFor="let child of children" [routerLink]="'/groups/edit/' + child.id">
            <h4 mat-line>{{child.groupName}}</h4>
            <span mat-line>{{child.type}}</span>
          </a>
        </mat-nav-list>
        <h3 *ngIf="children.length == 0">No children found</h3>
      </mat-card-content>
    </mat-card>
  </div>
</form>
