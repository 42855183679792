import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { PersonService } from '../../person.service';
import { MatDialogRef } from '@angular/material/dialog';
import { PersonWithStaff } from '../../person';
export class QuickAddComponent {
    constructor(personService, data, dialogRef) {
        this.personService = personService;
        this.data = data;
        this.dialogRef = dialogRef;
        this.person = new PersonWithStaff();
    }
    ngOnInit() {
    }
    cancel() {
        this.dialogRef.close();
    }
    add() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.person = yield this.personService.updatePerson(this.person);
            this.dialogRef.close(this.person);
        });
    }
}
