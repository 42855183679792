<ng-container *appTemplateContent="'title'">{{showingMine ? 'My Leave' : 'Leave'}}
  ({{dataSource.filteredData.length}})
</ng-container>
<ng-container *appToolbarContent>
  <button mat-icon-button>
    <mat-icon>search</mat-icon>
  </button>
  <mat-form-field floatLabel="never">
    <input autofocus matInput
           [value]="urlBinding.values.search"
           (keyup)="urlBinding.values.search = $event.target.value"
           placeholder="Search">
  </mat-form-field>
  <mat-slide-toggle [(ngModel)]="urlBinding.values.showApproved">Show Approved</mat-slide-toggle>
  <app-export-button [values]="dataSource"
                     [columns]="showingMine ? personColumns : hrColumns"
                     fileName="leaveData"></app-export-button>
</ng-container>
<mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="requesterName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Leave for</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.requesterName}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.type | leaveTypeName}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="approved">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Approved</mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-checkbox disabled [checked]="row.approved"></mat-checkbox>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="approvedByName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Approved by</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.approvedByName}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="startDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.startDate | date}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="endDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header>End Date</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.endDate | date}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="createdDate">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.createdDate | date}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="days">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Length</mat-header-cell>
    <mat-cell *matCellDef="let row">{{row.days}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="schoolYear">
    <mat-header-cell *matHeaderCellDef mat-sort-header="">School Year</mat-header-cell>
    <mat-cell *matCellDef="let row">{{yearNameFromDate(row.startDate)}}</mat-cell>
  </ng-container>
  <!-- Header and Row Declarations -->
  <mat-header-row *matHeaderRowDef="showingMine ? personColumns : hrColumns"></mat-header-row>
  <mat-row class="hover-overlay" [routerLink]="'/leave-request/edit/' + row.id"
           *matRowDef="let row; columns: showingMine ? personColumns : hrColumns">
  </mat-row>
</mat-table>
