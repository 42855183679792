<span class="mat-caption" *ngIf="caption">{{caption}}</span>
<mat-accordion>
  <mat-expansion-panel *ngIf="addNew" (opened)="newItem = newItem ? newItem : createNewItem()">
    <mat-expansion-panel-header>
      <mat-panel-title>Add new {{itemTitle}}</mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container *ngIf="content">
      <ng-template matExpansionPanelContent>
        <ng-container *ngTemplateOutlet="content; context: {$implicit: newItem,  index: -1}"></ng-container>
      </ng-template>
    </ng-container>
    <mat-action-row *ngIf="showActions">
      <button type="button"
              id="save-new"
              (click)="findForm(-1).ngForm.ngSubmit.emit()"
              [disabled]="(forms | findForm: -1)?.ngForm.invalid" mat-button>
        Add {{itemTitle}}
      </button>
    </mat-action-row>
  </mat-expansion-panel>

  <ng-content select="before"></ng-content>

  <mat-expansion-panel *ngFor="let item of items; index as index">
    <mat-expansion-panel-header *ngIf="header">
      <ng-container *ngTemplateOutlet="header; context: {$implicit: item, index: index}"></ng-container>
    </mat-expansion-panel-header>
    <ng-container *ngIf="content">
      <ng-template matExpansionPanelContent>
        <ng-container *ngTemplateOutlet="content; context: {$implicit: item,  index: index}"></ng-container>
      </ng-template>
    </ng-container>
    <mat-action-row *ngIf="showActions">
      <ng-container *ngIf="customAction">
        <ng-container *ngTemplateOutlet="customAction; context: {$implicit: item,  index: index}"></ng-container>
        <span class="spacer"></span>
      </ng-container>
      <button type="button" mat-button (click)="onDelete(item)" color="warn">Delete</button>
      <button type="button"
              [id]="'save-' + index"
              (click)="findForm(index).ngForm.ngSubmit.emit()"
              [disabled]="(forms | findForm: index)?.ngForm.invalid" mat-button>
        Save {{itemTitle}}
      </button>
    </mat-action-row>
  </mat-expansion-panel>


  <ng-content select="after"></ng-content>
</mat-accordion>
