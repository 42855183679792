import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StaffTraining } from '../staff-training';
import { TrainingRequirementService } from '../training-requirement.service';
import { Year } from '../year';
import { BehaviorSubject } from 'rxjs';
import { map, pluck, share } from 'rxjs/operators';
import { PersonService } from '../../person.service';
import * as moment from 'moment';
import { GroupService } from '../../groups/group.service';
export class TrainingReportComponent {
    constructor(route, trainingService, personService, orgGroupService, router) {
        this.route = route;
        this.trainingService = trainingService;
        this.personService = personService;
        this.orgGroupService = orgGroupService;
        this.router = router;
        this.completedDate = moment();
        this.staffTraining = new BehaviorSubject(null);
        this.showCompleted = new BehaviorSubject(true);
        this.years = Year.years();
        this.selectedYearSubject = new BehaviorSubject(null);
        this.route.params.pipe(pluck('year'), map(value => value || Year.CurrentSchoolYear()), map(yearValue => this.years.find(year => year.value == yearValue))).subscribe(this.selectedYearSubject);
        this.selectedYearSubject.subscribe(year => {
            this.selectedYear = year;
            this.completedDate = year.convertToSchoolYear(this.completedDate);
        });
        this.route.queryParamMap.pipe(map(params => {
            return params.has('showCompleted') ? params.get('showCompleted') == 'true' : true;
        })).subscribe(this.showCompleted);
        let groups = this.orgGroupService.getAll().pipe(share());
        let staff = this.personService.getStaffWithRoles().pipe(share());
        let trainingRequirements = this.trainingService.list().pipe(share());
        this.route.data.pipe(pluck('staffTraining')).subscribe(this.staffTraining);
        this.requirementsWithStaff = this.trainingService.buildRequirementsWithStaff(staff, groups, trainingRequirements, this.staffTraining.asObservable(), this.selectedYearSubject.pipe(pluck('value')), this.showCompleted);
    }
    ngOnInit() {
    }
    setYear(year) {
        this.updateNavigation(year, this.showCompleted.getValue());
    }
    get isLastYear() {
        return this.years[0].value === this.selectedYear.value;
    }
    get isFirstYear() {
        return this.years[this.years.length - 1].value === this.selectedYear.value;
    }
    setShowCompleted(show) {
        this.updateNavigation(this.selectedYear.value, show);
    }
    updateNavigation(year, showCompleted) {
        this.router.navigate([this.route.snapshot.params['year'] ? '..' : '.', year], {
            relativeTo: this.route,
            queryParams: { showCompleted: showCompleted }
        });
    }
    completeTraining(reqObject, index) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const staffWithTraining = reqObject.staffsWithTraining[index];
            const staffTraining = new StaffTraining();
            staffTraining.trainingRequirementId = reqObject.requirement.id;
            staffTraining.staffId = staffWithTraining.staff.id;
            staffTraining.completedDate = this.completedDate.toDate();
            yield this.trainingService.saveStaffTraining(staffTraining);
            this.trainingService.getStaffTrainingByYearMapped(this.selectedYear.value)
                .subscribe((training) => this.staffTraining.next(training));
        });
    }
    markSelectedComplete(reqObject) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const staffIds = reqObject.staffsWithTraining
                .filter(value => !value.training.completedDate && value.selected)
                .map(value => value.staff.id);
            yield this.trainingService.markAllComplete(staffIds, reqObject.requirement.id, this.completedDate.toDate());
            this.trainingService.getStaffTrainingByYearMapped(this.selectedYear.value)
                .subscribe((training) => this.staffTraining.next(training));
        });
    }
    markAllComplete(reqObject) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const staffIds = reqObject.staffsWithTraining
                .filter(value => !value.training.completedDate)
                .map(value => value.staff.id);
            yield this.trainingService.markAllComplete(staffIds, reqObject.requirement.id, this.completedDate.toDate());
            this.trainingService.getStaffTrainingByYearMapped(this.selectedYear.value)
                .subscribe((training) => this.staffTraining.next(training));
        });
    }
    allSelected(reqObject) {
        let hasSelected = false;
        let hasNotSelected = false;
        for (let value of reqObject.staffsWithTraining.filter(st => !st.training.completedDate)) {
            if (value.selected) {
                hasSelected = true;
            }
            else {
                hasNotSelected = true;
            }
            if (hasNotSelected && hasSelected)
                return null;
        }
        return hasSelected && !hasNotSelected;
    }
    selectAll(reqObject, selected) {
        for (let value of reqObject.staffsWithTraining.filter(st => !st.training.completedDate)) {
            value.selected = selected;
        }
    }
}
