import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { of } from 'rxjs';
import { AttachmentService } from './attachment.service';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, tap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../dialog/confirm-dialog/confirm-dialog.component';
import { BaseDestroy } from '../classes/base-destroy';
export class AttachmentsComponent extends BaseDestroy {
    constructor(attachmentService, dialog) {
        super();
        this.attachmentService = attachmentService;
        this.dialog = dialog;
    }
    ngOnInit() {
        this.attachmentService.extractId()
            .pipe(tap(value => this.attachedToId = value.id), switchMap((value) => {
            if (value.hasAttachments)
                return this.attachmentService.attachedOn(value.id);
            return of([]);
        }), this.takeUntilDestroy())
            .subscribe(attachments => {
            this.attachments = attachments;
        });
    }
    removeAttachment(attachment) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let result = yield ConfirmDialogComponent.OpenWait(this.dialog, `Delete Attachment ${attachment.name}?`, 'Delete', 'Cancel');
            if (!result)
                return;
            yield this.attachmentService.removeAttachment(attachment.id).toPromise();
            this.attachments = this.attachments.filter(value => value.id != attachment.id);
        });
    }
    newAttachment(attachment) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            attachment.attachedToId = this.attachedToId;
            attachment = yield this.attachmentService.attach(attachment).toPromise();
            this.attachments = [attachment, ...this.attachments];
        });
    }
}
