/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./render-template-bottom-sheet.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./render-template-bottom-sheet.component";
import * as i4 from "@angular/material/bottom-sheet";
import * as i5 from "../../toolbar/app-template.service";
var styles_RenderTemplateBottomSheetComponent = [i0.styles];
var RenderType_RenderTemplateBottomSheetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RenderTemplateBottomSheetComponent, data: {} });
export { RenderType_RenderTemplateBottomSheetComponent as RenderType_RenderTemplateBottomSheetComponent };
function View_RenderTemplateBottomSheetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_RenderTemplateBottomSheetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RenderTemplateBottomSheetComponent_2)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.context; var currVal_1 = _v.context.ngIf; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_RenderTemplateBottomSheetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_RenderTemplateBottomSheetComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.templateObservable)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RenderTemplateBottomSheetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-render-template-bottom-sheet", [], null, null, null, View_RenderTemplateBottomSheetComponent_0, RenderType_RenderTemplateBottomSheetComponent)), i1.ɵdid(1, 114688, null, 0, i3.RenderTemplateBottomSheetComponent, [i4.MAT_BOTTOM_SHEET_DATA, i4.MatBottomSheetRef, i5.AppTemplateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RenderTemplateBottomSheetComponentNgFactory = i1.ɵccf("app-render-template-bottom-sheet", i3.RenderTemplateBottomSheetComponent, View_RenderTemplateBottomSheetComponent_Host_0, {}, {}, []);
export { RenderTemplateBottomSheetComponentNgFactory as RenderTemplateBottomSheetComponentNgFactory };
