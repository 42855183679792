import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnInit, QueryList } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
export class AccordionListComponent {
    constructor(snackBar, dialog, element) {
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.element = element;
        this.caption = null;
        this.itemTitle = 'Item';
        this.addNew = true;
        this.showActions = true;
        this.items = [];
        this.itemsChange = new EventEmitter();
        this.forms = [];
    }
    onDelete(item) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let result = yield ConfirmDialogComponent.OpenWait(this.dialog, `Delete ${this.itemTitle}?`, 'Delete', 'Cancel');
            if (!result)
                return;
            let deleteResult = this.delete(item);
            if ('toPromise' in deleteResult)
                deleteResult = deleteResult.toPromise();
            yield deleteResult;
            if ('id' in item) {
                this.itemsChange.emit(this.items.filter(value => value.id != item.id));
            }
            this.snackBar.open(`${this.itemTitle} Deleted`, null, { duration: 2000 });
        });
    }
    onSave(item, panel, form, isNew = false) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let saveResult = this.save(item);
            if ('toPromise' in saveResult)
                saveResult = saveResult.toPromise();
            item = yield saveResult;
            this.snackBar.open(`${this.itemTitle} ${isNew ? 'Added' : 'Saved'}`, null, { duration: 2000 });
            panel.close();
            if (isNew) {
                this.itemsChange.emit([...this.items, item]);
                this.newItem = this.createNewItem();
                form.resetForm();
            }
        });
    }
    ngOnInit() {
        if (this.save == null)
            throw new Error('Save callback not provided');
        if (this.delete == null)
            throw new Error('Delete callback not provided');
        if (this.header == null)
            throw new Error('Missing *appAccordionListHeader');
        if (this.content == null)
            throw new Error('Missing *appAccordionListContent');
    }
    findIndex(form) {
        let foundIndex = -1;
        this.expansionPanelElements.forEach((item, index) => {
            if (foundIndex != -1)
                return;
            if (form.hasAsParent(item.nativeElement)) {
                foundIndex = index;
            }
        });
        return foundIndex - 1;
    }
    addForm(form) {
        this.forms = [...this.forms, form];
    }
    removeForm(form) {
        this.forms = [...this.forms.filter(value => value == form)];
    }
    findForm(index) {
        for (let form of this.forms) {
            if (form.getContext().index == index)
                return form;
        }
        return null;
    }
    matchesElement(element) {
        return this.element.nativeElement == element;
    }
}
