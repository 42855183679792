<ng-container *appTemplateContent="'title'">Edit {{missionOrg.name}}</ng-container>
<ng-container *appToolbarContent>
  <button mat-icon-button matTooltip="View Staff" (click)="showStaff()">
    <mat-icon>people</mat-icon>
  </button>
</ng-container>
<ng-container *appTemplateContent="'staff'; let sheet">
  <mat-nav-list *ngIf="peopleInOrg.length > 0; else empty">
    <h3 mat-subheader>
      <mat-icon mat-list-icon>person</mat-icon>
      Staff ({{peopleInOrg.length}})
    </h3>
    <a mat-list-item *ngFor="let person of peopleInOrg" [routerLink]="['/people', 'edit', person.id]"
       (click)="sheet.dismiss()">{{person.preferredName}} {{person.lastName}}</a>
  </mat-nav-list>
  <ng-template #empty>
    <h3>No Staff work for this Sending Org</h3>
  </ng-template>
</ng-container>
<div class="main-card">
  <mat-card>
    <form id="form" (submit)="save()" class="two-colum-grid">

      <mat-form-field>
        <input matInput required name="name" [(ngModel)]="missionOrg.name" placeholder="Name">
      </mat-form-field>
      <div class="form-field-button">
        <mat-form-field>
          <mat-select name="orgRep"
                      [(ngModel)]="missionOrg.repId"
                      required
                      placeholder="Org Rep">
            <mat-option [value]="null">None</mat-option>
            <mat-option *ngFor="let eachPerson of people" [value]="eachPerson.id">{{eachPerson.preferredName}}
              {{eachPerson.lastName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-quick-add-button
          [(personId)]="missionOrg.repId"
          caption="Rep"
          (updateList)="people.push($event)">
        </app-quick-add-button>
      </div>

      <mat-form-field>
        <mat-select name="status"
                    [(ngModel)]="missionOrg.status"
                    placeholder="Status">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let status of statusList" [value]="status">{{status | titleCase}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <input name="approvedDate" matInput
               [(ngModel)]="missionOrg.approvedDate"
               [matDatepicker]="approvedDatePicker"
               placeholder="Date Approved">
        <mat-datepicker-toggle matSuffix [for]="approvedDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #approvedDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <input matInput [(ngModel)]="missionOrg.email"
               type="email"
               name="email"
               placeholder="Email">
      </mat-form-field>
      <mat-form-field>
        <input matInput [(ngModel)]="missionOrg.phone"
               type="tel"
               name="phone"
               placeholder="Phone Number">
      </mat-form-field>
      <mat-form-field class="span-2-columns">
          <textarea matInput [(ngModel)]="missionOrg.address"
                    name="address"
                    placeholder="Home Address"></textarea>
      </mat-form-field>
      <mat-form-field class="span-2-columns">
          <textarea matInput [(ngModel)]="missionOrg.addressLocal"
                    name="addressLocal"
                    placeholder="Local Address"></textarea>
      </mat-form-field>
      <mat-slide-toggle name="inThailand" [(ngModel)]="missionOrg.officeInThailand">Office is in Thailand
      </mat-slide-toggle>
    </form>
    <mat-card-actions>
      <button type="submit" form="form" mat-button>Save</button>
      <button type="button" *ngIf="!isNew" color="warn" (click)="delete()" mat-button>Delete</button>
    </mat-card-actions>
  </mat-card>
  <app-accordion-list [createNewItem]="createNewYear"
                      [save]="saveYear"
                      [delete]="deleteYear"
                      [(items)]="missionOrg.yearSummaries"
                      caption="Year Summaries"
                      itemTitle="Year">
    <ng-container *appAccordionListHeader="let item;">
      <mat-panel-title>{{item.year}}</mat-panel-title>
      <mat-panel-description>{{item.level}}</mat-panel-description>
    </ng-container>
    <ng-container *appAccordionListContent="let item; index as index">
      <form appAccordionListForm class="two-colum-grid">
        <mat-form-field>
          <input [(ngModel)]="item.studentCount" required type="number" placeholder="Student Count"
                 name="studentCount"
                 matInput>
        </mat-form-field>
        <mat-form-field>
          <input [(ngModel)]="item.teacherCount" required type="number" placeholder="Teacher Count"
                 name="teacherCount"
                 matInput>
        </mat-form-field>
        <mat-form-field>
          <mat-select name="status"
                      [(ngModel)]="item.status"
                      placeholder="Status">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let status of statusList" [value]="status">{{status | titleCase}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select name="level"
                      [(ngModel)]="item.level"
                      placeholder="Level">
            <mat-option>None</mat-option>
            <mat-option *ngFor="let level of levelList" [value]="level">{{level | titleCase}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select [(ngModel)]="item.year"
                      required
                      name="year"
                      placeholder="Year">
            <mat-option [value]="null">None</mat-option>
            <mat-option *ngFor="let year of schoolYears" [value]="year.value">{{year.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </ng-container>
  </app-accordion-list>
</div>
