import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class HolidayService {
    constructor(http) {
        this.http = http;
    }
    listHolidays() {
        return this.http.get('/api/holiday');
    }
    currentHolidays() {
        return this.http.get('/api/holiday/current');
    }
    getHolidayById(id) {
        return this.http.get('/api/holiday/' + id);
    }
    saveHoliday(holiday) {
        return this.http.post('/api/holiday', holiday);
    }
    deleteHoliday(holidayId) {
        return this.http.delete('/api/holiday/' + holidayId);
    }
}
HolidayService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HolidayService_Factory() { return new HolidayService(i0.ɵɵinject(i1.HttpClient)); }, token: HolidayService, providedIn: "root" });
