import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { LoginService } from './login.service';
import { User } from '../../user/user';
import { CookieService } from 'ngx-cookie-service';
export class AuthenticateService {
    constructor(http, loginService, cookieService) {
        this.http = http;
        this.loginService = loginService;
        this.cookieService = cookieService;
    }
    login(username, currentPassword, newPassword) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let body = {
                Username: username,
                Password: currentPassword
            };
            if (newPassword)
                body['NewPassword'] = newPassword;
            let json = yield this.http.post(`/api/authenticate/${newPassword ? 'reset' : 'signin'}`, body).toPromise();
            return this.postLogin(json);
        });
    }
    impersonate(email) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let user = this.postLogin(yield this.http.get('/api/authenticate/impersonate/' + encodeURI(email))
                .toPromise());
            this.cookieService.delete('.Sub');
            return user;
        });
    }
    postLogin(json) {
        let user = Object.assign(new User(), json.user);
        if (!user.resetPassword) {
            //we won't get an access token back if the users password needs to be reset
            this.loginService.setLoggedIn(json.access_token);
        }
        return user;
    }
}
