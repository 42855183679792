<ng-container *appTemplateContent="'title'">Certifications ({{dataSource.filteredData.length}})</ng-container>
<ng-container *appToolbarContent>
  <button mat-icon-button>
    <mat-icon>search</mat-icon>
  </button>
  <mat-form-field floatLabel="never">
    <input autofocus matInput
           [value]="urlBinding.values.search"
           (keyup)="urlBinding.values.search = $event.target.value"
           placeholder="Search">
  </mat-form-field>
  <app-export-button [values]="dataSource" fileName="certificationsData"></app-export-button>
</ng-container>
<mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.name}}</mat-cell>
  </ng-container>

  <!-- Header and Row Declarations -->
  <mat-header-row
    *matHeaderRowDef="['name']"></mat-header-row>
  <mat-row class="hover-overlay"
           *matRowDef="let row; columns: ['name']"
           [routerLink]="'/endorsement/edit/' + row.id"></mat-row>
</mat-table>

<a routerLink="/endorsement/edit/new" class="add-button" mat-fab matTooltip="Add new Certification"
   matTooltipPosition="left">
  <mat-icon>add</mat-icon>
</a>
