<ng-container *appTemplateContent="'title'">Departments/Divisions ({{dataSource.filteredData.length}})</ng-container>
<ng-container *appToolbarContent>
  <button mat-icon-button>
    <mat-icon>search</mat-icon>
  </button>
  <mat-form-field floatLabel="never">
    <input autofocus matInput placeholder="Search" [(ngModel)]="urlBinding.values.search">
  </mat-form-field>
  <app-export-button fileName="departments"
                     [values]="dataSource"
                     [columns]="columns"></app-export-button>
</ng-container>
<mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="groupName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.groupName}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="parent">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Parent</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{groupNameById[row.parentId]}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="supervisor">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Supervisor</mat-header-cell>
    <mat-cell *matCellDef="let row"
              [class.warn]="supervisorCountById[row.supervisor] > 1"> {{(row.supervisorPerson?.preferredName || row.supervisorPerson?.firstName || '') +
      ' ' + (row.supervisorPerson?.lastName || '')}}
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.type | groupTypeName}}</mat-cell>
  </ng-container>
  <!-- Header and Row Declarations -->
  <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
  <mat-row class="hover-overlay" *matRowDef="let row; columns: columns"
           [routerLink]="'../edit/' + row.id"></mat-row>
</mat-table>


<a routerLink="../edit/new" class="add-button" mat-fab matTooltip="Add new Group" matTooltipPosition="left">
  <mat-icon>add</mat-icon>
</a>
