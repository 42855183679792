import { JobStatus } from '../job/job';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
export class OrgTree {
    constructor(data, urlBinding, rootId) {
        this.supervisorCountById = {};
        this.data = data;
        this.urlBinding = urlBinding;
        this.nodes = data.groups.filter(org => org.parentId == null || org.id == rootId)
            .map(org => this.buildOrgNode(org, this.data));
        for (let group of data.groups) {
            if (!group.supervisor)
                continue;
            this.supervisorCountById[group.supervisor] = (this.supervisorCountById[group.supervisor] || 0) + 1;
        }
    }
    buildJobNode(job, data) {
        return new JobOrgNode(job.id, job, 'job', data.roles
            .filter(role => role.jobId == job.id)
            .map(role => this.buildRoleNode(role, data)), this.jobFilter());
    }
    jobFilter() {
        if (!this.urlBinding)
            return undefined;
        return combineLatest(this.urlBinding.observableValues.allRoles)
            .pipe(map(([allRoles]) => (value) => value.active || allRoles));
    }
    buildRoleNode(role, data) {
        return new RoleOrgNode(role.personId, role, 'role', []);
    }
    buildOrgNode(org, data) {
        return new GroupOrgNode(org.id, org, 'org', [
            ...data.jobs
                .filter(value => value.orgGroupId == org.id)
                .map(value => this.buildJobNode(value, data)),
            ...data.groups
                .filter(value => value.parentId == org.id)
                .map(value => this.buildOrgNode(value, data))
        ], this.orgFilter());
    }
    orgFilter() {
        if (!this.urlBinding)
            return undefined;
        return combineLatest(this.urlBinding.observableValues.allJobs, this.urlBinding.observableValues.show)
            .pipe(map(([allJobs, show]) => (child) => {
            if ('title' in child) {
                if (!allJobs && !child.current)
                    return false;
                if (child.status == JobStatus.SchoolAid && !show.includes('aids'))
                    return false;
                if (child.status != JobStatus.SchoolAid && !show.includes('staff'))
                    return false;
            }
            return true;
        }));
    }
}
export class OrgTreeData {
}
export class OrgNode {
    constructor(id, value, type, children, observableFilter) {
        this.parent = null;
        this.id = id;
        this.value = value;
        this.type = type;
        this.allChildren = children;
        for (let child of this.allChildren) {
            child.parent = this;
        }
        this.observableChildren = new BehaviorSubject(this.allChildren);
        if (observableFilter)
            observableFilter.pipe(map(filter => this.allChildren.filter(child => filter(child.value))))
                .subscribe(this.observableChildren);
    }
    get hasChildren() {
        return this.filteredChildren.length > 0;
    }
    get filteredChildren() {
        return this.observableChildren.value;
    }
    get level() {
        return !this.parent ? 0 : (this.parent.level + 1);
    }
    get openJobs() {
        return 0;
    }
    get jobsAvalible() {
        return 0;
    }
    get activeStaff() {
        return 0;
    }
    get activeAids() {
        return 0;
    }
    get allDecendantsGroups() {
        return [];
    }
}
export class JobOrgNode extends OrgNode {
    get openJobs() {
        return this.jobsAvalible - (this.filteredChildren.filter(child => child.value.active).length);
    }
    get jobsAvalible() {
        return this.value.positions;
    }
    get activeStaff() {
        if (this.value.status == JobStatus.SchoolAid)
            return 0;
        return (this.filteredChildren.filter(child => child.value.active).length);
    }
    get activeAids() {
        if (this.value.status != JobStatus.SchoolAid)
            return 0;
        return (this.filteredChildren.filter(child => child.value.active).length);
    }
}
export class GroupOrgNode extends OrgNode {
    constructor() {
        super(...arguments);
        this._allDecendantsGroups = null;
    }
    get openJobs() {
        return this.filteredChildren.reduce((previousValue, currentValue) => previousValue + currentValue.openJobs, 0);
    }
    get jobsAvalible() {
        return this.filteredChildren.reduce((previousValue, currentValue) => previousValue + currentValue.jobsAvalible, 0);
    }
    get activeStaff() {
        return this.filteredChildren.reduce((previousValue, currentValue) => previousValue + currentValue.activeStaff, 0);
    }
    get activeAids() {
        return this.filteredChildren.reduce((previousValue, currentValue) => previousValue + currentValue.activeAids, 0);
    }
    get allDecendantsGroups() {
        if (this._allDecendantsGroups == null) {
            this._allDecendantsGroups = [this.value.groupName].concat(...this.allChildren.map(value => value.allDecendantsGroups));
        }
        return this._allDecendantsGroups;
    }
}
export class RoleOrgNode extends OrgNode {
}
