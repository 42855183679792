<ng-container *appTemplateContent="'title'">Calendar</ng-container>
<ng-container *appToolbarContent>
  <mat-form-field>
    <mat-select placeholder="Filter Department" [(ngModel)]="urlBinding.values.group" multiple>
      <mat-option *ngFor="let groupName of allOrgGroups" [value]="groupName">{{groupName}}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="month-nav">
    <button mat-icon-button (click)="incrementMonth(-1)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <span>{{month.format('MMMM YYYY')}}</span>
    <button mat-icon-button (click)="incrementMonth(1)">
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>
</ng-container>

<div class="cal-table" id="calendar">
  <span>Sunday</span>
  <span>Monday</span>
  <span>Tuesday</span>
  <span>Wednesday</span>
  <span>Thursday</span>
  <span>Friday</span>
  <span>Saturday</span>
  <div *ngFor="let model of models" class="date-cell">
    <ng-container *ngIf="model.dayOfMonth == 1">{{model.monthName}}</ng-container>
    {{model.dayOfMonth}}
    <button (click)="leaveSheet.show(req)" mat-button
            style="display: block"
            *ngFor="let req of model.items">
      <ng-container *ngIf="!req.isPublic">
        <span [title]="req.approved ? 'Approved' : 'Pending'">{{req.approved ? '✓' : '?'}}</span>
        [{{req.type | leaveTypeName}}]
      </ng-container>
      {{req.requesterName}}
    </button>
  </div>
</div>

<ng-template appBottomSheet let-leave #leaveSheet="bottomSheet">
  <a mat-button [routerLink]="['/leave-request', 'edit', leave.id]">
    <mat-icon>transfer_within_a_station</mat-icon>
    View Request
  </a>
  <button mat-icon-button
          (click)="leaveSheet.dismiss()"
          style="float: right;"
          matTooltip="Close">
    <mat-icon>close</mat-icon>
  </button>
  <div class="two-colum-grid leave-summary">
    <mat-form-field>
      <input matInput readonly [value]="leave.requesterName" placeholder="Name">
    </mat-form-field>
    <mat-form-field *ngIf="!leave.isPublic">
      <input matInput readonly [value]="leave.type | leaveTypeName" placeholder="Type">
    </mat-form-field>
    <div class="span-2-columns">
      <mat-form-field>
        <input matInput readonly [value]="leave.startDate | date" placeholder="Start Date">
      </mat-form-field>
      <mat-form-field>
        <input matInput readonly [value]="leave.endDate | date" placeholder="End Date">
      </mat-form-field>
    </div>
    <mat-form-field>
      <input matInput readonly [value]="leave.days == 0.5 ? '1/2' : leave.days" placeholder="Length (days)">
    </mat-form-field>
    <mat-checkbox *ngIf="!leave.isPublic" readonly [checked]="leave.approved">Approved</mat-checkbox>

    <mat-form-field class="span-2-columns" *ngIf="leave.reason">
      <input matInput readonly [value]="leave.reason" placeholder="Reason">
    </mat-form-field>
  </div>
</ng-template>
