import { BaseEntity } from '../classes/base-entity';
export class Job extends BaseEntity {
    constructor() {
        super();
        this.current = true;
    }
}
export class JobWithFilledInfo extends Job {
}
export class JobWithOrgGroup extends Job {
}
export class JobWithRoles extends Job {
    constructor() {
        super(...arguments);
        this.roles = [];
        this.requiredEndorsements = [];
    }
}
export var JobStatus;
(function (JobStatus) {
    JobStatus["FullTime"] = "FullTime";
    JobStatus["FullTime10Mo"] = "FullTime10Mo";
    JobStatus["HalfTime"] = "HalfTime";
    JobStatus["Contractor"] = "Contractor";
    JobStatus["DailyWorker"] = "DailyWorker";
    JobStatus["SchoolAid"] = "SchoolAid";
})(JobStatus || (JobStatus = {}));
const ɵ1 = value => JobStatus[value], ɵ0 = value => value != JobStatus.SchoolAid;
export const NonSchoolAidJobStatus = Object.keys(JobStatus)
    .map(ɵ1)
    .filter(ɵ0);
export var JobType;
(function (JobType) {
    JobType["Admin"] = "Admin";
    JobType["Support"] = "Support";
    JobType["Teacher"] = "Teacher";
    JobType["BlueCollar"] = "BlueCollar";
})(JobType || (JobType = {}));
const ɵ2 = value => JobType[value];
export const AllJobTypes = Object.keys(JobType)
    .map(ɵ2);
export { ɵ1, ɵ0, ɵ2 };
