import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { BaseEditComponent } from '../../components/base-edit-component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EndorsementService } from '../endorsement.service';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
export class EndorsementComponent extends BaseEditComponent {
    constructor(endorsementService, route, location, snackBar, dialog) {
        super(dialog);
        this.endorsementService = endorsementService;
        this.route = route;
        this.location = location;
        this.snackBar = snackBar;
        this.route.data.subscribe(value => {
            this.endorsement = value.endorsement;
            this.isNew = !this.endorsement.id;
        });
    }
    ngOnInit() {
    }
    save() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.endorsement = yield this.endorsementService.saveEndorsement(this.endorsement).toPromise();
            this.location.back();
            this.snackBar.open(`${this.endorsement.name} ${this.isNew ? 'Added' : 'Saved'}`, null, { duration: 2000 });
        });
    }
    deleteEndorsement() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let result = yield ConfirmDialogComponent.OpenWait(this.dialog, 'Delete Certification?', 'Delete', 'Cancel');
            if (!result)
                return;
            yield this.endorsementService.deleteEndorsement(this.endorsement.id).toPromise();
            this.location.back();
            this.snackBar.open(`${this.endorsement.name} Deleted`, null, { duration: 2000 });
        });
    }
}
