<ng-container *appTemplateContent="'title'">Login</ng-container>
<ng-container *appToolbarContent>
  <button mat-icon-button matTooltip="Manual Login" (click)="loginSheet.show()">
    <mat-icon>input</mat-icon>
  </button>
</ng-container>
<div class="login">
  <h1 i18n="login page welcome@@welcomeLogin">Welcome to GIS Office</h1>
  <a mat-stroked-button color="accent" class="google-login"
     [href]="'/api/authenticate/google?redirectTo=' + redirectUrl">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"
         version="1.1" width="24px" height="24px">
      <g id="surface1">
        <path style=" fill:#FFC107;"
              d="M 43.609375 20.082031 L 42 20.082031 L 42 20 L 24 20 L 24 28 L 35.304688 28 C 33.652344 32.65625 29.222656 36 24 36 C 17.371094 36 12 30.628906 12 24 C 12 17.371094 17.371094 12 24 12 C 27.058594 12 29.84375 13.152344 31.960938 15.039063 L 37.617188 9.382813 C 34.046875 6.054688 29.269531 4 24 4 C 12.953125 4 4 12.953125 4 24 C 4 35.046875 12.953125 44 24 44 C 35.046875 44 44 35.046875 44 24 C 44 22.660156 43.863281 21.351563 43.609375 20.082031 Z "/>
        <path style=" fill:#FF3D00;"
              d="M 6.304688 14.691406 L 12.878906 19.511719 C 14.65625 15.109375 18.960938 12 24 12 C 27.058594 12 29.84375 13.152344 31.960938 15.039063 L 37.617188 9.382813 C 34.046875 6.054688 29.269531 4 24 4 C 16.316406 4 9.65625 8.335938 6.304688 14.691406 Z "/>
        <path style=" fill:#4CAF50;"
              d="M 24 44 C 29.164063 44 33.859375 42.023438 37.410156 38.808594 L 31.21875 33.570313 C 29.210938 35.089844 26.714844 36 24 36 C 18.796875 36 14.382813 32.683594 12.71875 28.054688 L 6.195313 33.078125 C 9.503906 39.554688 16.226563 44 24 44 Z "/>
        <path style=" fill:#1976D2;"
              d="M 43.609375 20.082031 L 42 20.082031 L 42 20 L 24 20 L 24 28 L 35.304688 28 C 34.511719 30.238281 33.070313 32.164063 31.214844 33.570313 C 31.21875 33.570313 31.21875 33.570313 31.21875 33.570313 L 37.410156 38.808594 C 36.972656 39.203125 44 34 44 24 C 44 22.660156 43.863281 21.351563 43.609375 20.082031 Z "/>
      </g>
    </svg>
    Login with Google
  </a>
</div>

<ng-template #loginSheet="bottomSheet" appBottomSheet>
  <form #loginForm="ngForm" id="form" (ngSubmit)="login()">
    <h4>Manual Login</h4>
    <mat-form-field>
      <input #usernameElement="ngModel"
             name="username"
             autofocus
             matInput
             required
             [(ngModel)]="username"
             placeholder="Username"
             i18n-placeholder="input hint for username at login@@usernameLoginInput">
      <mat-error *ngIf="usernameElement.valid || usernameElement.pristine"
                 i18n="message telling user that the username is required@@usernameRequiredError">
        Username required
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <input #passwordElement="ngModel"
             type="password"
             name="password"
             matInput
             required
             [(ngModel)]="password"
             [placeholder]="passwordReset ? 'Current Password' : 'Password'"
             i18n-placeholder="input hint for password at login@@passwordLoginInput">
      <mat-hint>Enter to Login</mat-hint>
      <mat-error *ngIf="passwordElement.valid || passwordElement.pristine"
                 i18n="message telling user that the password is required@@passRequiredError">
        Password required
      </mat-error>
    </mat-form-field>
    <ng-container *ngIf="passwordReset">
      <mat-form-field>
        <input matInput
               required
               #newPasswordElement="ngModel"
               name="newPassword"
               minlength="8"
               autocomplete="off"
               [(ngModel)]="newPassword"
               type="password"
               placeholder="New Password"
               i18n-placeholder="user password@@NewPassword">
        <mat-error *ngIf="newPasswordElement.invalid && (newPasswordElement.dirty || newPasswordElement.touched)"
                   i18n="password error@@passwordLengthWrong">
          Password must be at least 8 characters long
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput
               required
               #newPasswordElement2="ngModel"
               name="newPassword2"
               minlength="8"
               autocomplete="off"
               ngModel
               [pattern]="newPasswordElement.value"
               type="password"
               placeholder="Confirm new Password"
               i18n-placeholder="user password confirm@@newPasswordConfirm">
        <mat-error
          *ngIf="newPasswordElement2.invalid && (newPasswordElement2.dirty || newPasswordElement2.touched || newPasswordElement.touched)"
          i18n="password matching error@@passwordsMustMatch">
          Passwords must match
        </mat-error>
      </mat-form-field>
    </ng-container>

    <mat-error *ngIf="errorMessage">
      {{errorMessage}}
    </mat-error>
    <button mat-button
            hidden
            type="submit"
            form="form"
            [disabled]="!loginForm.form.valid"
            i18n="login button@@loginButton">
      Login
    </button>
  </form>
</ng-template>
