import { enableProdMode } from '@angular/core';
import * as moment from 'moment';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';
import 'hammerjs';
import * as Sentry from '@sentry/browser';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
Sentry.init({ dsn: 'https://026d43df17b245588298bfa5ac8aa333@sentry.io/249854', environment: 'production' });
if (environment.production) {
    enableProdMode();
}
moment.fn.toJSON = function () {
    return this.format('YYYY-MM-DD[T]HH:mm:ss.SSS');
};
const bootstrap = () => __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory);
const ɵ0 = bootstrap;
if (environment.hmr) {
    if (module['hot']) {
        hmrBootstrap(module, bootstrap);
    }
    else {
        console.error('Ammm.. HMR is not enabled for webpack');
    }
}
else {
    bootstrap();
}
export { ɵ0 };
