import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
export class SandboxComponent {
    constructor(http) {
        this.http = http;
    }
    ngOnInit() {
    }
    test() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log('date:', this.date.toJSON());
            let val = yield this.http.post('api/test', { date: this.date }).toPromise();
            console.log('date back:', moment(val.date).toISOString(true));
        });
    }
}
