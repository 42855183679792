import { BaseEntity } from '../classes/base-entity';
export var Gender;
(function (Gender) {
    Gender["Male"] = "Male";
    Gender["Female"] = "Female";
})(Gender || (Gender = {}));
export class Person extends BaseEntity {
    constructor() {
        super(...arguments);
        this.gender = Gender.Male;
    }
}
export class PersonExtended extends Person {
}
export class PersonWithRoleSummaries extends PersonExtended {
}
export class PersonWithStaff extends PersonExtended {
}
export class PersonWithStaffSummaries extends PersonWithStaff {
}
export class PersonWithOthers extends PersonWithStaff {
    constructor() {
        super(...arguments);
        this.roles = [];
        this.emergencyContacts = [];
        this.evaluations = [];
        this.staffEndorsements = [];
        this.donations = [];
        this.education = [];
    }
}
export class PersonWithDaysOfLeave extends Person {
}
