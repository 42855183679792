/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./render-template-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/common";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./render-template-dialog.component";
import * as i9 from "../../toolbar/app-template.service";
var styles_RenderTemplateDialogComponent = [i0.styles];
var RenderType_RenderTemplateDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RenderTemplateDialogComponent, data: {} });
export { RenderType_RenderTemplateDialogComponent as RenderType_RenderTemplateDialogComponent };
function View_RenderTemplateDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null)], null, null); }
export function View_RenderTemplateDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 3, null, View_RenderTemplateDialogComponent_1)), i1.ɵdid(4, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(5, { $implicit: 0 }), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 5, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["mat-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).dialogRef.close(i1.ɵnov(_v, 11).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(11, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Close"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_2 = _ck(_v, 5, 0, _co.dialogRef); var currVal_3 = (_co.template ? _co.template : i1.ɵunv(_v, 4, 1, i1.ɵnov(_v, 6).transform(_co.templateObservable))); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_7 = "accept"; _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 2, 0, currVal_1); var currVal_4 = (i1.ɵnov(_v, 10).disabled || null); var currVal_5 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); var currVal_6 = (i1.ɵnov(_v, 11).ariaLabel || null); _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6); }); }
export function View_RenderTemplateDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-render-template-dialog", [], null, null, null, View_RenderTemplateDialogComponent_0, RenderType_RenderTemplateDialogComponent)), i1.ɵdid(1, 114688, null, 0, i8.RenderTemplateDialogComponent, [i2.MAT_DIALOG_DATA, i2.MatDialogRef, i9.AppTemplateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RenderTemplateDialogComponentNgFactory = i1.ɵccf("app-render-template-dialog", i8.RenderTemplateDialogComponent, View_RenderTemplateDialogComponent_Host_0, {}, {}, []);
export { RenderTemplateDialogComponentNgFactory as RenderTemplateDialogComponentNgFactory };
