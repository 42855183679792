import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrainingRequirementService } from '../training-requirement.service';
import { TrainingScope } from '../training-requirement';
import { Year } from '../year';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../../../dialog/confirm-dialog/confirm-dialog.component';
import { BaseEditComponent } from '../../../components/base-edit-component';
import { JobType } from '../../../job/job';
export class TrainingEditComponent extends BaseEditComponent {
    constructor(route, trainingService, router, dialog, snackBar) {
        super(dialog);
        this.route = route;
        this.trainingService = trainingService;
        this.router = router;
        this.snackBar = snackBar;
        this.jobTypes = Object.keys(JobType);
        this.isNew = false;
    }
    ngOnInit() {
        this.route.data.subscribe((value) => {
            this.groups = value.groups;
            this.people = value.people;
            this.training = value.training;
            this.isNew = !this.training.id;
        });
        this.years = Year.years();
    }
    get for() {
        if (this.training.scope !== TrainingScope.Department)
            return this.training.scope;
        return this.training.departmentId;
    }
    set for(value) {
        if (value == TrainingScope.AllStaff) {
            this.training.scope = value;
        }
        else {
            this.training.scope = TrainingScope.Department;
            this.training.departmentId = value;
        }
    }
    save() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.trainingService.save(this.training);
            this.router.navigate(['training', 'list']);
            this.snackBar.open(`Training Requirement ${this.isNew ? 'Added' : 'Saved'}`, null, { duration: 2000 });
        });
    }
    delete() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield ConfirmDialogComponent.OpenWait(this.dialog, `Deleting Training requirement, any training records will also be deleted`, 'Delete', 'Cancel');
            if (!result)
                return;
            yield this.trainingService.deleteRequirement(this.training.id);
            this.router.navigate(['training', 'list'], { relativeTo: this.route });
            this.snackBar.open(`Training Requirement Deleted`, null, { duration: 2000 });
        });
    }
}
