import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BaseEditComponent } from '../../components/base-edit-component';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.component';
import { HolidayService } from '../holiday.service';
export class HolidayComponent extends BaseEditComponent {
    constructor(holidayService, route, location, snackBar, dialog) {
        super(dialog);
        this.holidayService = holidayService;
        this.route = route;
        this.location = location;
        this.snackBar = snackBar;
    }
    ngOnInit() {
        this.route.data.subscribe(value => {
            this.holiday = value.holiday;
            this.isNew = !this.holiday.id;
        });
    }
    save() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.holiday = yield this.holidayService.saveHoliday(this.holiday).toPromise();
            this.location.back();
            this.snackBar.open(`${this.holiday.name} ${this.isNew ? 'Added' : 'Saved'}`, null, { duration: 2000 });
        });
    }
    deleteHoliday() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let result = yield ConfirmDialogComponent.OpenWait(this.dialog, 'Delete Holiday?', 'Delete', 'Cancel');
            if (!result)
                return;
            yield this.holidayService.deleteHoliday(this.holiday.id).toPromise();
            this.location.back();
            this.snackBar.open(`${this.holiday.name} Deleted`, null, { duration: 2000 });
        });
    }
}
